export const consultationConditionEdit: any = {
  thankYouTitle: "Thank you for your inquiry",
  thankYouMessage:
    "Please wait for a while as we will reply later in chat later.",
  additionalConditionFormTitle: "Additional hope",
  additionalConditionFormDescription1:
    "If you have any hopes about hotels and flights,",
  additionalConditionFormDescription2:
    "please check all applicable items and send them.",
  additionalConditionFormSubmitComplete:
    "Send completed. \nThank you very much.",
  additionalConditionFormSubmit: "Send hopes",
  regionSelectionNote: "If not selected, a convenient region will be selected",
};
