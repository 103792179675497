export const common: any = {
  update: "UPDATE",
  create: "CREATE",
  createNew: "CREATE NEW",
  publish: "PUBLISH",
  saveDraft: "SAVE DRAFT",
  changeToDraft: "CHANGE TO DRAFT",
  add: "ADD",
  delete: "DELETE",
  clear: "CLEAR",
  embed: "EMBED",
  dismiss: "DISMISS",
  showMore: "SHOW MORE",
  research: "SEARCH AGAIN",
  closeMore: "CLOSE",
  cancel: "CANCEL",
  reset: "RESET",
  reserve: "BOOK",
  detail: "DETAIL",
  schedule: "SCHEDULE",
  showAll: "Show all",
  showLess: "Show less",
  logIn: "LOG IN",
  lineAddFriend: "LINE Add Friend",
  travelConsultationOnLine: "Travel Consultation on LINE",
  send: "SEND",
  showDetail: "Show detail",
  hideDetail: "Hide detail",
  select: "SELECT",
};
