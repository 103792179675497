export const rentalCarArrangement = {
  status: {
    created: "created",
    arranged: "arranged",
    cancelled: "cancelled",
  },
  arrangementType: {
    expedia: "expedia",
    agent_car: "agent_car",
  },
};
