export const seo = {
  siteName: "たびチャット",
  titleChat: "チャット",
  titleChatUnit: "ユーザー登録",
  titleMasterMessage: "テンプレ登録",
  titleMasterMessageGroups: "メッセージグループ",
  titleCrmMessageSchedules: "CRMメッセージスケジュール",
  titleHotels: "ホテル",
  titleActivities: "アクティビティ",
  titleRestaurants: "レストラン",
  titleReservations: "予約",
  titleScenarios: "シナリオ",
  titleInHouseNotice: "社内通知",
  titleLpsIndex: "LPコンテンツ一覧",
  titleLpsNew: "LPコンテンツ作成",
  titleLpsEdit: "LPコンテンツ編集",
  titleEmailChatUnit: "メールユーザー登録",
};
