export const reservation: any = {
  lineLoginBlockTitle: "LINEでログインがおすすめ！",
  lineLoginMerit01: "チャットに予約情報が届きます",
  lineLoginMerit02: "チャットでコンシェルジュに相談できます",
  updatePassportInfo: "パスポート情報の更新",
  updatePassportInfoNotice:
    "航空券の発券にはパスポート情報が必要です。できるだけ早くパスポート情報を入力してください。",
  passportInformationUpdated: "パスポート情報を更新しました",
  passportInfoNotRequiredNotice:
    "予約時には不要ですが、出発までにパスポート情報をお送りください",
};
