export const reservationShow: any = {
  title: "Reservation Details",
  reservationId: "Reservation ID",
  reservationDate: "Reservation Date",
  accommodationDate: "Accommodation Date",
  flightSummary: "Flight Summary",
  travelerInfo: "Travelers Information",
  flightDetails: "Flight Details",
  priceSummary: "Price Summary",
  flightPriceSummary: "Flight Price Summary",
  hotelPriceSummary: "Hotel Price Summary",
  regulation: "Rules & Restrictions",
  help: "Help",
  hotelOverview: "Hotel Overview",
  cancellationPenalty: "Cancellation penalty of {{fee}} will occur",
  noCancellation: "No Cancellations",
  cancelThisRoom: "Cancel this room",
  customer: "Customers",
  roomType: "Room type",
  planContent: "Plan contents",
  guestName: "Guest name",
  room: "Rooms",
  hotelRoom: "Hotel rooms",
  hotelPolicy: "Hotel policy",
  hotelTitle: "Hotel",
  flightTitle: "Flight",
  hotel: "Hotel",
  totalPrice: "Total price",
  hotelSummary: "Hotel Summary",
  showHotelDetails: "Show hotel details",
  hotelCancelAndChange: "Hotel cancellations and changes",
  flightCancelAndChange: "Flight cancellations and changes",
  roomConfirmation: "Room confirmation",
  aboutHotel: "About hotel",
  aboutReservation: "About this reservation",
};
