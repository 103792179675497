export const optionalTourCategories = {
  sightseeing: "Sightseeing",
  airportPickup: "Airport pickup",
  restaurantReservation: "Restaurant reservation",
  themepark: "Theme park",
  musium: "Museum",
  shopping: "Shopping",
  showEntertainment: "Show entertainment",
  expediences: "Expediences",
  spaEsthetic: "Spa esthetic",
  worldHeritage: "World heritage",
  skydiving: "Skydiving",
  golf: "Golf",
  waterSports: "Water sports",
  scubaDiving: "Scuba diving",
  cruise: "Cruise",
  dayCruise: "Day cruise",
  mobileWifiRental: "Mobile wifi rental",
  wedding: "Wedding",
  activity: "Activity",
  rentACar: "Rent a car",
};
