export const flightArrangementPrice = {
  priceType: {
    taxes: "税金",
    bookingDistributionFee: "予約販売手数料",
    distributionFee: "販売手数料",
    roundingAdjustment: "調整金額",
    creditCardSurcharge: "クレジットカードサーチャージ",
    luggageSurcharge: "手荷物サーチャージ",
    comboServiceFee: "コンボサービスフィー",
    bankConversionCharge: "通貨換算手数料",
    bankHandlingCharge: "銀行手数料",
    reservedSeatsIncludedSurcharge: "座席予約サーチャージ",
    otherTaxes: "その他",
    baseFare: "航空券",
    taxAndSurcharge: "税 / 燃油サーチャージなど",
    arrangeFee: "取扱料金",
    baggage: "預け入れ手荷物",
    itinerary0Baggage: "預け入れ手荷物(往路)",
    itinerary1Baggage: "預け入れ手荷物(復路)",
  },
};
