export const crmMessageSchedule = {
  crmMessageScheduleList: "CRM Message Schedule List",
  crmMessageSchedule: "CRM Message Schedule",
  crmMessageScheduleEdit: "Schedule edit",
  messageGroup: "Message group",
  scheduleName: "Schedule name",
  addMessages: "Add messages",
  operation: "Operation",
  triggerCondition: "Trigger conditions",
  action: "Action",
  carousel: "Carousel",
  actionData: "Action data",
  sendScheduleAt: "Send schedule at",
  sentAt: "Sent at",
  sendNow: "Send now",
  exclusionCondition: "Exclusion conditions",
  inclusionCondition: "Inclusion conditions",
  addConditions: "Adding conditions",
  minRangeDate: "Min date",
  maxRangeDate: "Max date",
  lineType: "LINE type",
  status: "Status",
  recipientsCount: "Number of recipients",
  chatUnitId: "Chat ID",
};
