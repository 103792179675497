export const railwayArrangement = {
  status: {
    created: "created",
    invoiced: "invoiced",
    arranged: "arranged",
    cancelled: "cancelled",
    waiting_for_arrangement: "waiting for arrangement",
    confirmed: "confirmed",
    refunding: "refunding",
    refunded: "refunded",
  },
  arrangementType: {
    tumlare: "tumlare",
    go_euro: "go_euro",
    omio: "omio",
  },
  initialCancelPolicy: "No changes or refunds after payment has been made.",
};
