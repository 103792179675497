export const hotelArrangementPlan = {
  status: {
    created: "作成済",
    invoiced: "請求済み",
    arranged: "仮予約",
    cancelled: "キャンセル済",
    unconfirmed: "不明",
    confirmed: "確定済み",
    confirming: "確定予定",
    refunding: "返金依頼",
    refunded: "返金済み",
  },
  mealType: {
    nothing: "食事なし",
    breakfast: "朝食付き",
    dinner: "夕食付き",
    both: "朝・夕付き",
    breakfast_and_dinner: "朝・夕付き",
    all_meals: "朝・昼・夕付き",
  },
  payType: {
    pre_payment: "サイト払い",
    on_site: "ホテル支払い",
  },
};
