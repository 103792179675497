export const flightArrangement = {
  status: {
    created: "created",
    arranged: "arranged",
    cancelled: "cancelled",
    void: "VOID",
    ticketing: "ticketing",
    ticketed: "ticketed",
    refunding: "refunding",
    refunded: "refunded",
  },
  arrangementType: {
    jetstar: "Jetstar",
    expedia: "Expedia",
    peach: "Peach",
    airline_site: "Airline Site",
    amadeus_by_phone: "amadeus by manual",
    apollo_by_phone: "apollo by manual",
    infini_by_phone: "infini by manual",
    amadeus: "Amadeus",
    infini_lcc: "Infini LCC",
    travelfusion: "Travelfusion",
  },
};
