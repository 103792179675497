export const input = {
  title: "タイトル：",
  image: "画像：",
  address: "住所：",
  introductionSentences: "紹介文：",
  freeWords: "フリーワード：",
  carouselSet: "カルーセルセット",
  action: "アクション",
  flight: "フライト",
  price: "料金：",
  arrangeFee: "手数料：",
  priceDisplay: "料金表示：",
  yen: "円",
  placeholderSearchUser: "ユーザーを名前やIDで検索",
  placeholderImageUrl: "画像URLを入力...",
  placeholderText: "テキストを入力...",
  placeholderMemo: "メモを入力...",
  placeholderRecommended: "おすすめを入力...",
  placeholderInput: "入力してください...",
  placeholderDestination: "行き先を入力してください...",
  placeholderQuery: "キーワードを入力してください...",
  placeholderFlightCityOrAirport: "都市名 または 空港名",
  placeholderDate: "年 / 月 / 日",
  placeholderNameOrAddressOrUrl: "名前か住所かURLを入力してください...",
  placeholderHope: "旅の希望を入力...",
  placeholderQuickReplyActionData: "action=action_data&action_id=1",
  placeholderQuickReplyActionLabel: "ラベル",
  placeholderQuickReplyActionDisplayText: "表示テキスト",
  placeholderHotelSelection: "ホテル名を入力してください...",
  placeholderAirlineSelection: "航空会社名を入力してください...",
  placeholderSpecialRequest: "例) Extra bed 1",
  placeholderEmail: "メールアドレスを入力...",
  placeholderScheduleName: "スケジュール名を入力してください...",
  placeholderLineType: "LINEタイプを入力してください...",
};
