export const payment = {
  status: {
    created: "created",
    authorized: "authorized",
    confirmed: "confirmed",
    refunded: "refunded",
    failed: "failed",
    cancelled: "cancelled",
  },
  paymentType: {
    on_site: "On Site",
    credit_card: "Credit Card",
    bank_transfer: "Bank Transfer",
    later_payment: "later Payment",
    advance_payment: "Advance Payment",
    deposit_payment: "Deposit Payment",
    government_benefit: "Government Benefit",
  },
  billerType: {
    unknown: "unknown",
    fness: "f-ness",
    oversease_travel: "OTA",
    travelfusion: "travelfusion",
    vueling: "Vueling",
    expedia: "expedia",
    his: "his",
    marinabay: "marina bay",
    veltra: "veltra",
    omio: "omio",
    tpi: "TPI",
    other: "Other",
  },
};
