export const crmMessageSchedule = {
  crmMessageScheduleList: "CRMメッセージスケジュール一覧",
  crmMessageSchedule: "CRMメッセージスケジュール",
  crmMessageScheduleEdit: "スケジュール編集",
  messageGroup: "メッセージグループ",
  scheduleName: "スケジュール名",
  addMessages: "メッセージを追加する",
  operation: "操作",
  triggerCondition: "発動条件",
  action: "アクション",
  carousel: "カルーセル",
  actionData: "アクションデータ",
  sendScheduleAt: "配信予定日時",
  sentAt: "配信日時",
  sendNow: "すぐに配信する",
  exclusionCondition: "除外条件",
  inclusionCondition: "フィルタ条件",
  addConditions: "条件の追加",
  minRangeDate: "最小の日付",
  maxRangeDate: "最大の日付",
  lineType: "LINEタイプ",
  status: "ステータス",
  recipientsCount: "対象人数",
  chatUnitId: "チャットID",
};
