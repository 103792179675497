import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { componentJa } from "./main/javascripts/locales/component/ja";
import { componentEn } from "./main/javascripts/locales/component/en";
import { modelEn } from "./main/javascripts/locales/model/en";
import { modelJa } from "./main/javascripts/locales/model/ja";
import { paymentEn } from "./main/javascripts/locales/payment/en";
import { paymentJa } from "./main/javascripts/locales/payment/ja";
import { constantEn } from "./main/javascripts/locales/constant/en";
import { constantJa } from "./main/javascripts/locales/constant/ja";
import { labelEn } from "./main/javascripts/locales/label/en";
import { labelJa } from "./main/javascripts/locales/label/ja";
import { unitEn } from "./main/javascripts/locales/unit/en";
import { unitJa } from "./main/javascripts/locales/unit/ja";
import { validationEn } from "./main/javascripts/locales/validation/en";
import { validationJa } from "./main/javascripts/locales/validation/ja";
import { buttonEn } from "./main/javascripts/locales/button/en";
import { buttonJa } from "./main/javascripts/locales/button/ja";

import { labelEn as managerLabelEn } from "manager/javascripts/locales/label/en";
import { labelJa as managerLabelJa } from "manager/javascripts/locales/label/ja";
import { constantEn as managerConstantEn } from "manager/javascripts/locales/constant/en";
import { constantJa as managerConstantJa } from "manager/javascripts/locales/constant/ja";
import { componentJa as managerComponentJa } from "manager/javascripts/locales/component/ja";
import { componentEn as managerComponentEn } from "manager/javascripts/locales/component/en";
import { messageEn as managerMessageEn } from "manager/javascripts/locales/message/en";
import { messageJa as managerMessageJa } from "manager/javascripts/locales/message/ja";
import { modelEn as managerModelEn } from "manager/javascripts/locales/model/en";
import { modelJa as managerModelJa } from "manager/javascripts/locales/model/ja";
import { unitEn as managerUnitEn } from "manager/javascripts/locales/unit/en";
import { unitJa as managerUnitJa } from "manager/javascripts/locales/unit/ja";
import { buttonEn as managerButtonEn } from "manager/javascripts/locales/button/en";
import { buttonJa as managerButtonJa } from "manager/javascripts/locales/button/ja";

void i18n.use(initReactI18next).init({
  resources: {
    en: {
      component: componentEn,
      model: modelEn,
      payment: paymentEn,
      constant: constantEn,
      label: labelEn,
      unit: unitEn,
      validation: validationEn,
      button: buttonEn,
      managerLabel: managerLabelEn,
      managerConstant: managerConstantEn,
      managerComponent: managerComponentEn,
      managerMessage: managerMessageEn,
      managerModel: managerModelEn,
      managerUnit: managerUnitEn,
      managerButton: managerButtonEn,
    },
    ja: {
      component: componentJa,
      model: modelJa,
      payment: paymentJa,
      constant: constantJa,
      label: labelJa,
      unit: unitJa,
      validation: validationJa,
      button: buttonJa,
      managerLabel: managerLabelJa,
      managerConstant: managerConstantJa,
      managerComponent: managerComponentJa,
      managerMessage: managerMessageJa,
      managerModel: managerModelJa,
      managerUnit: managerUnitJa,
      managerButton: managerButtonJa,
    },
  },
  fallbackLng: "ja",
  lng: "ja",
  interpolation: {
    escapeValue: false, // not needed for react
  },
  ns: ["component", "model", "payment", "label", "unit", "validation",
    "managerLabel", "managerConstant", "managerComponent", "managerMessage",
    "managerModel", "managerUnit", "managerButton"],
});
export default i18n;
