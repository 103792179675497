export const optionalTourCategories = {
  sightseeing: "観光ツアー",
  airportPickup: "空港送迎サービス",
  restaurantReservation: "レストラン予約",
  themepark: "テーマパーク",
  musium: "美術館／博物館",
  shopping: "ショッピング",
  showEntertainment: "ショー・エンターテイメント",
  expediences: "文化体験・学習",
  spaEsthetic: "スパ／エステ／マッサージ",
  worldHeritage: "世界遺産",
  skydiving: "スカイダイビング",
  golf: "ゴルフ",
  waterSports: "ウォータースポーツ",
  scubaDiving: "スキューバダイビング",
  cruise: "クルーズ",
  dayCruise: "デイクルーズ",
  mobileWifiRental: "携帯電話　WiFiレンタル",
  wedding: "ウェディング",
  activity: "アクティビティ",
  rentACar: "レンタカー",
};
