export const flightArrangement = {
  status: {
    created: "作成済",
    arranged: "手配済",
    cancelled: "キャンセル済",
    void: "発券キャンセル済",
    ticketing: "発券依頼中",
    ticketed: "発券済",
    refunding: "返金依頼中",
    refunded: "返金済",
  },
  arrangementType: {
    jetstar: "Jetstar",
    expedia: "Expedia",
    peach: "Peach",
    airline_site: "航空会社サイト",
    amadeus_by_phone: "アマデウス手動手配",
    apollo_by_phone: "アポロ手動手配",
    infini_by_phone: "インフィニ手動手配",
    amadeus: "アマデウス",
    infini_lcc: "Infini LCC",
    travelfusion: "Travelfusion",
  },
};
