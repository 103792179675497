export const reputation: any = {
  title: "TabiChat's reputation",
  led: "In the post-trip survey,\n we received a 94% satisfaction rating.",
  reference: "Reference",
  voice1: "voice1",
  person1: "person1",
  voice2: "voice1",
  person2: "person1",
  voice3: "voice1",
  person3: "person1",
  voice4: "voice1",
  person4: "person1",
  voice5: "voice1",
  person5: "person1",
  voice6: "voice1",
  person6: "person1",
};
