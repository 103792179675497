export const searchColumnType = {
  id: "id",
  name: "name.raw",
  places: "places",
  address: "address",
  tags: "tags",
  tripadvisorUrl: "tripadvisor_url",
  tabelogUrl: "tabelog_url",
  introductions: "introductions",
  genres: "genres",
  tripadvisorRating: "tripadvisor_rating",
  totalTravelkoNum: "total_travelkos_num",
  totalExpediaNum: "total_expedia_num",
  totalBookingComNum: "total_booking_com_num",
  enabledTravelkoNum: "enabled_travelkos_num",
  disabledTravelkoNum: "disabled_travelkos_num",
  disabledIntroductionNum: "disabled_introduction_num",
  introductionNum: "introduction_num",
  disabledTagNum: "disabled_tag_num",
  tagNum: "tag_num",
};
