import { hotelReservation } from "./hotelReservation/en";
import { hotelArrangement } from "./hotelArrangement/en";
import { hotelArrangementPlanFee } from "./hotelArrangementPlanFee/en";
import { hotelArrangementPlan } from "./hotelArrangementPlan/en";
import { hotelArrangementPlanPrice } from "./hotelArrangementPlanPrice/en";
import { flightArrangementTravelerPrice } from "./flightArrangementTravelerPrice/en";
import { flightReservation } from "./flightReservation/en";
import { flightArrangementPrice } from "./flighArrangementPrice/en";
import { reservation } from "./reservation/en";

export const modelEn: any = {
  hotelReservation,
  hotelArrangement,
  hotelArrangementPlanFee,
  hotelArrangementPlan,
  hotelArrangementPlanPrice,
  flightArrangementTravelerPrice,
  flightArrangementPrice,
  flightReservation,
  reservation,
};
