export const form: any = {
  firstNamePlaceholder: "John",
  lastNamePlaceholder: "Smith",
  roomTitle: "■ Information on travelers",
  travelerTitle: "Information on travelers",
  guestTitle: "Information on guests",
  passengerTitle: "Information on passengers",
  additionalTitle: "Additional information",
  submitFormError: "An error occurred in the input form",
  submitError: "An error occurred",
  passportNumberPlaceholder: "e.g. TX1234567",
  edit: "EDIT",
  hotelSpecialRequestPlaceholder: "e.g. Extra bed 1",
  titlePlaceholder: "Input title...",
  summaryPlaceholder: "Input summary...",
  tagPlaceholder: "Select or input tag...",
  relatedPostsPlaceholder: "Enter part of the title",
  lineFooterTextPlaceholder: "Consult a trip to the UK & France",
};
