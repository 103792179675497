export const flightArrangementTraveler = {
  status: {
    created: "created",
    invoiced: "invoiced",
    arranged: "arranged",
    ticketing: "ticketing",
    unconfirmed: "unconfirmed",
    waitingForTicketed: "waiting for ticketed",
    ticketed: "ticketed",
    void: "VOID",
    cancelled: "cancelled",
    refunding: "refunding",
    refunded: "refunded",
    failed: "failed",
  },
};
