export const flightArrangementTravelerPrice: any = {
  priceType: {
    baseFare: "Flight",
    taxAndSurcharge: "Taxes / Fuel Surcharge",
    arrangeFee: "Handling Charge",
    baggage: "Baggage",
    itinerary0Baggage: "Outward baggage",
    itinerary1Baggage: "Return baggage",
  },
};
