import { common } from "./common/ja";
import { payment } from "./payment/ja";
import { charge } from "./charge/ja";
import { reservation } from "./reservation/ja";
import { hotelArrangement } from "./hotelArrangement/ja";
import { hotelArrangementPlan } from "./hotelArrangementPlan/ja";
import { flightArrangement } from "./flightArrangement/ja";
import { flightArrangementTraveler } from "./flightArrangementTraveler/ja";
import { flightArrangementTravelerOption } from "./flightArrangementTravelerOption/ja";
import { flightArrangementOption } from "./flightArrangementOption/ja";
import { flightArrangementPrice } from "./flightArrangementPrice/ja";
import { chatUnit } from "./chatUnit/ja";
import { optionalTourArrangement } from "./optionalTourArrangement/ja";
import { rentalCarArrangement } from "./rentalCarArrangement/ja";
import { wifiArrangement } from "./wifiArrangement/ja";
import { railwayArrangement } from "./railwayArrangement/ja";
import { cruiseArrangement } from "./cruiseArrangement/ja";
import { insuranceArrangement } from "./insuranceArrangement/ja";
import { searchInput } from "./searchInput/ja";
import { masterMessage } from "./masterMessage/ja";
import { hotelArrangementPlanPrice } from "./hotelArrangementPlanPrice/ja";
import { hotelArrangementPlanFee } from "./hotelArrangementPlanFee/ja";
import { otherArrangement } from "./otherArrangement/ja";
import { discount } from "./discount/ja";
import { delayBatchEvent } from "./delayBatchEvent/ja";
import { scenario } from "./scenario/ja";
import { crmMessageSchedule } from "./crmMessageSchedule/ja";
import { masterMessageGroup } from "./masterMessageGroup/ja";

export const modelJa = {
  common,
  payment,
  charge,
  reservation,
  hotelArrangement,
  hotelArrangementPlan,
  flightArrangement,
  flightArrangementTraveler,
  flightArrangementTravelerOption,
  flightArrangementOption,
  flightArrangementPrice,
  optionalTourArrangement,
  rentalCarArrangement,
  wifiArrangement,
  railwayArrangement,
  cruiseArrangement,
  insuranceArrangement,
  chatUnit,
  searchInput,
  masterMessage,
  hotelArrangementPlanPrice,
  hotelArrangementPlanFee,
  otherArrangement,
  discount,
  delayBatchEvent,
  scenario,
  crmMessageSchedule,
  masterMessageGroup,
};
