export const messageJa = {
  hotelText: `┏━┓
┃田┣━┓たびチャット
┃田┃田┃オススメの
┃田┃田┃厳選宿
¬¬¬¬¬¬¬¬¬¬¬¬¬¬¬
●{{name}}
¬¬¬¬¬¬¬¬¬¬¬¬¬¬¬
住所：{{address}}

{{value}}

{{freeText}}`,
  roomCountText: " x {{roomCount}}部屋",
  roomsLeftText: `【残り{{roomsLeft}}部屋のみ】\n`,
  roomIndex: `部屋{{index}}：`,
  numberOfAdultsText: `大人{{numberOfAdult}}名`,
  numberOfChildrenText: ` 子供{{numberOfChildren}}名`,
  hotelFreeText: `◆◆━━━━━━━━
◆ 宿泊プラン案内
━━━━━━━━━
日程：{{checkinDate}}〜{{checkoutDate}}
人数：{{generateGuestsText}}
食事：{{meals}}
部屋：{{roomName}}{{roomCountText}}

合計料金：{{inclusiveTotalPrice}}
（{{inclusivePricePerDayPerRoom}}/1泊1部屋）
---
{{freeCancellationText}}
{{planFeesText}}
{{roomsLeftText}}`,
  noCancellations: "キャンセル不可",
  cancellationFree: "{{costCancellationChargeDate}}までホテルのキャンセル無料",
  totalPrice: "\n合計：{{totalPrice}}",
  flightDetailText: `📍{{departureAirportName}}
{{departureDate}} {{departureTime}}

↓ ✈️ {{airlineName}}

{{arrivalDate}} {{arrivalTime}}{{deltaDate}}
📍{{arrivalAirportName}}`,
  stopover: `- - - - - - - - - - - - - - -
  乗継：{{layover}}
- - - - - - - - - - - - - - -
`,
  deltaDate: "(+{{deltaDate}}日)",
  flightItineraryText: `
▼ 経路{{index}}:【{{duration}}】
{{flightDetailsText}}
`,
  flightText: `✈️ フライトの詳細
{{flightItinerariesText}}

👜 【無料】預け入れ手荷物
━━━━━━━━━
{{handBaggageText}}
{{additionalBaggageText}}

{{priceText}}`,
  quantityFreeBaggagePerPerson: "預け入れ手荷物1名あたり{{quantity}}個まで無料",
  quantityWeightFreeBaggagePerPerson:
    "預け入れ手荷物1名あたり{{quantity}}個/{{weight}}{{weightUnit}}まで無料",
  weightFreeBaggagePerPerson:
    "預け入れ手荷物1名あたり{{weight}}{{weightUnit}}まで無料",
  noFreeBaggage: "預け入れ手荷物なし",
  noFreeBaggageInformation: "情報なしのためお問い合わせ",
  additionalBaggage:
    "\n👜 【有料】預け入れ手荷物\n━━━━━━━━━\n{{additionalBaggageText}}",
  baggageWithItinerary: "経路{{index}}：{{baggageText}}",
  hotelRecommendedSentenceMessage:
    "🏨 おすすめのホテル\n━━━━━━━━━\n{{freeText}}\n{{sentencesText}}\n\n▼▼ 詳細は下記に記載 ▼▼",
  actionInitText: "👉 詳しく見る 👈",
  none: "なし",
  messageButtonText: `＜予約の流れ＞
⑴ 予約フォームを入力
⑵ 内容確認連絡が届く
⑶ 料金の支払いへ進む`,
  clickHereForReservation: "👉予約フォームへ進む",
  trainCostDefaultText: `🚄 鉄道移動
━━━━━━━━━
x ⇒ x
📅日程
1/1 
00:00-00:00
Eurostar

出発：x駅
到着：x駅
料金：0円

※ 料金は日付・時間帯・空席状況などにより変動いたします。

※ たびチャット手配料込です`,
  optionalTourCostDefaultText: `🚗 オプショナルツアー
━━━━━━━━━
title
📅日程
0/0

0円
(たびチャット手数料1,100円含む)`,
  additionalBaggageCostDefaultText: `💼 預け入れ手荷物
━━━━━━━━━━━
1個(~00kg)
0円x0名様

※ 無料の預け入れ手荷物がないフライトがございますので、上記の預け入れ手荷物を追加して合計料金を計算いたします。
`,
  hotelSearchConditionText: `📍{{destination}}
🗓{{checkinDate}}-{{checkoutDate}}（{{stayNum}}泊）
{{guestNum}}`,
};
