export const reservationShow: any = {
  title: "予約内容の詳細",
  reservationId: "予約番号",
  reservationDate: "予約日",
  accommodationDate: "宿泊日",
  flightSummary: "フライトの概要",
  travelerInfo: "搭乗者情報",
  flightDetails: "フライト詳細",
  priceSummary: "料金の概要",
  flightPriceSummary: "フライト料金概要",
  hotelPriceSummary: "ホテル料金概要",
  regulation: "規則と制限事項",
  baggageInfo: "手荷物情報",
  baggageInfoDetail: "詳細",
  help: "ヘルプ",
  hotelOverview: "ホテルの概要",
  cancellationPenalty: "{{fee}}のキャンセルペナルティが発生します",
  noCancellation: "キャンセル不可",
  cancelThisRoom: "この客室をキャンセルする",
  customer: "お客さま",
  roomType: "部屋タイプ",
  planContent: "含まれているプラン内容",
  guestName: "ご予約者名",
  room: "客室",
  hotelRoom: "ホテルの客室",
  hotelPolicy: "ホテルのポリシー",
  hotelTitle: "ホテルについて",
  flightTitle: "フライトについて",
  hotel: "ホテル",
  totalPrice: "合計料金",
  hotelSummary: "ホテルの概要",
  showHotelDetails: "ホテルの詳細を表示",
  hotelCancelAndChange: "ホテルのキャンセルおよび変更",
  flightCancelAndChange: "フライトのキャンセルおよび変更",
  roomConfirmation: "客室の確認",
  aboutHotel: "ホテルについて",
  aboutReservation: "予約について",
};
