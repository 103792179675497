export const flight: any = {
  operatingAirline: "運行会社: {{name}}",
  toAirport: "{{name}}まで",
  departingFlight: "往路",
  returningFlight: "復路",
  roundTripFlight: "往復",
  useLcc: "LCCの利用",
  notUseLcc: "LCCを利用しない",
  codeshare: "コードシェア便",
  excludeCodeshare: "コードシェア便を除外する",
  reservedSeat: "座席指定",
  firstDepartureTime: "出国時刻",
  lastArrivalTime: "帰国時刻",
  baggageDisplay: "{{bags}}個: {{weight}}まで ({{price}})",
  additionalBaggage: "追加預け入れ手荷物",
  addBaggage: "預け入れ手荷物を追加",
  quantityFreeBaggagePerPerson: "預け入れ手荷物1名あたり{{quantity}}個まで無料",
  quantityWeightFreeBaggagePerPerson:
    "預け入れ手荷物1名あたり{{quantity}}個/{{weight}}{{weightUnit}}まで無料",
  weightFreeBaggagePerPerson:
    "預け入れ手荷物1名あたり{{weight}}{{weightUnit}}まで無料",
  noFreeBaggage: "預け入れ手荷物なし",
  noFreeBaggageInformation: "情報なしのためお問い合わせ",
  itineraryNumber: "経路 {{index}}",
  baggageWithItinerary: "経路{{index}}：{{baggageText}}",
  baggageAllowance: "【無料】預け入れ手荷物について",
  additionalBaggageAllowance: "【有料】預け入れ手荷物について",
  bookingClass: "予約クラス",
  departFrom: "{{name}}出発",
  flightDetails: "フライト詳細",
  checkpoint: "チェックポイント",
  departureAirport: "出発空港",
  arrivalAirport: "到着空港",
  transitAirport: "乗継空港",
  airline: "航空会社",
  layover: "乗継時間",
  maxLayover: "最大乗継時間",
  departureHour1: "出発時刻1",
  departureHour2: "出発時刻2",
  departureHour3: "出発時刻3",
  departureHour4: "出発時刻4",
  departureHour5: "出発時刻5",
  lastDepartureHour: "最終出発時刻",
  lastArrivalHour: "最終到着時刻",
  freeBaggageType: "無料預け入れ手荷物",
  agreement: "上記の内容に同意する",
  outbound: "往路",
  inbound: "復路",
  selectedAdditionalBaggage: "【追加済み】預け入れ手荷物について",
  destination: "目的地",
  origin: "出発地",
  departureDate: "出発日",
  returnDate: "現地出発日",
  age: "年齢",
  stopCount: "経由回数",
  sortBy: "並び替え",
};
