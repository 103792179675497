export const hotelArrangement = {
  status: {
    created: "作成済",
    arranged: "手配済",
    cancelled: "キャンセル済",
  },
  arrangementType: {
    unknown: "不明",
    taap: "TAAP",
    expedia: "Expedia",
    rikisha: "Rikisha",
    hotel_site: "ホテルサイト",
    rka: "Rikisha API",
  },
  stayNum: "{{stayNum}}泊",
};
