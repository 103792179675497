export const chat = {
  pleaseSelectUser: "Please select any users",
  searchTotalNum: "Total Number: {{totalNum}}",
  flightConnecting: "Connect",
  flightTicketPrice: "Flight Ticket Price",
  arrangeServiceCharge: "Arrange Service Charge",
  totalPrice: "Total Price",
  flightPriceDetail: "Flight Ticke Price Detail",
  hotelPriceDetail: "Hotel Price Detail",
  grade: "Grade：{{grade}}\n",
  rating: "Rating：{{rating}} / 5.0\n{{ratingText}}\n",
  rating40Text: "(good)",
  rating45Text: "(great)",
  rating50Text: "(best)",
  arrangementCancelTitle: "Cancel?",
  arrangementCancelDescription: "Would you like to execute the canceled?",
  searchExpirationTitle: "Check the latest information",
  searchExpirationDescription:
    "In order to display the latest information, you need to update the search results.",
  searchRefresh: "Update",
  reservationExpirationTitle: "Session Expired",
  reservationExpirationDescription:
    "This reservation session has expired. Please search again.",
  reservationResearch: "Search again",
  reservationOutOfStockTitle: "Out of stock",
  reservationOutOfStockDescription:
    "This item is sold out. Please search again.",
  reservationPriceChangedTitle: "Price changed",
  reservationNewPriceConfirmationDescription:
    "The price of this flight changed ({{priceDifference}}). The new price is: {{newPrice}}.\n" +
    "Please confirm if you would like to proceed with this reservation.",
  reservationConfirmNewPriceButton: "Continue",
  reservationPriceChangedDescription:
    "The price of this item has changed. You need to update this page.",
  reservationRefresh: "Update",
  aboutCancellation: "Cancellation Policy",
  cancellationPolicy: "It can not be canceled or there was no information.",
  memoTemplateUseAlertDescription: "Would you like to use this template?",
  partialRefundTitle: "Partial refund",
  partialRefundDescription: "Enter the refund amount below.",
  correctPriceTitle: "Enter payment price",
  commissionBackTitle: "Comissionback",
  textSelectionInstruction:
    "Please select a range of text \nto be registered from the following",
  saveAsNewCondition: "Create Additional",
  annotationFormPlaceholder: "Please select range of message text.",
  messageForSearchData: "Message for search data",
  selectMessage: "Please select a message.",
  consultationContent: "Consultation",
  hopeContent: "Hope",
  reservationSummary: "Reservation Summary",
  searchCondition: "Flight Search Condition",
  hotelSearchCondition: "Hotel Search Condition",
  section: "Section",
  date: "Dates",
  traveler: "Travelers",
  origin: "Origin",
  destination: "Destination",
  restoreSearch: "Research",
  numberOfAdults: "{{number}} Adult(s)",
  numberOfChildren: "{{number}} Children",
  agesOfChildren: "Ages of children",
  originSummary: "Origin：{{origin}}",
  destinationSummary: "Destination：{{destination}}",
  departureDateSummary: "Departure: {{date}}",
  returnDateSummary: "Return: {{date}}",
  unsent: "Unsent",
  sentDate: "Sent date",
  noFlightData: "No flight data",
  noHotelData: "No hotel data",
  hotelPrice: "Hotel Price",
  questionSpecifiedMode: "QUESTION SPECIFIED",
  setReminderTitle: "Set Reminder",
  setReminderDescription: "Input date and memos.",
  setScheduledSendingTitle: "Set Scheduled　Sending",
  setScheduledSendingDescription: "Input date.",
  departFrom: "From {{name}}",
  fromTotalPrice: "From the total price",
  selectRecommendedSentences: "Select Recommended Sentences",
  autoSuggestionSuccessMessage:
    "Suggestion is being created. Please update page later.",
  saveReservationOnlyNotice:
    "Only the reservation information is saved in the DB. Please arrange separately.",
  reservationpartiallyFailedTitle: "Subsequent arrangements failed",
  reservationpartiallyFailedDescription:
    "An error occurred during multiple arrangements. Please check your reservation.",
  goToReservation: "Go to reservation details",
  unknownErrorTitle: "Unknown Error",
  unknownErrorDescription: "An unknown error has occurred",
  filterByThisHotel: "Filter by this hotel",
  filterByThisFlight: "Filter by this flight",
  hasSelectedRegions: "Region selected",
  refundableCancellationPolicy: "Partial refundable before departure.",
  noCancellation: "No Cancellations",
  cancellable: "Cancellable",
  cancellationFee: "Cancellation fee",
  totalRefundAmount: "Total refund amount",
  totalCancellationPenalty: "Total cancellation fee",
  cannotReservation:
    "Can not reservation now (reserve info creation is available)",
  displayAfterSave: "Display after save",
  autoCostCalculatedEditTitle: "Change automatic fee calculation",
  autoCostCalculatedEditDescription:
    "Many things can be handled automatically when the system calculates the charges automatically.\n" +
    "If you are unsure how to do it, please consult with Slack.",
  autoCostCalculatedEditButton: "Continue editing",
  flightPreReservation: "Flight reservation",
  hotelPreReservation: "Hotel reservation",
  adjustPriceTitle: "Adjust price",
  addPriceTitle: "Add price",
  addRefundTitle: "Add refund",
  hotelSpecialRequestNotice:
    "* Please fill in half-width alphanumeric characters. (Ex) Extra bed 1",
  confirmReservationDescription: "* Reservation is finalized and charged",
  confirmReservationWithNoCancellationDescription:
    "* Reservation is finalized and charged\n* No cancellation",
  selectOptionalTourPlanDateTitle:
    "Create a tour message for the specified date",
  selectOptionalTourPlanDateDescription:
    "Please select a date and number of participants",
  notAvailable: "Not available",
  outOfRange: "outOfRange",
  optionalTourFeeNotice:
    "* In the case of TabiChat arrangement, the above is +1,100 yen",
  optionalTourLinkText: "Here for details",
  optionalTourTitle: "🚩Optional tour\n━━━━━━━━━\n",
  showInVeltra: "Show in VELTRA",
  createCostMessage: "Create cost message",
  recommendedPlanSummary: "Summary of recommended plan",
  suggestionContents: "Suggestions by TabiChat",
  imageCreationMessage:
    "The images are being created. It will take a few minutes to complete.",
  itineraries: "Itineraries",
  splitItineraries: "Split Itineraries",
  showOriginalPrice: "Show Original Price",
};
