export const buttonJa = {
  add: "追加",
  save: "保存",
  create: "作成",
  select: "選択",
  edit: "編集",
  send: "送信",
  sendLong: "送信する",
  sendAll: "全て送信",
  update: "更新",
  updateLong: "更新する",
  remove: "削除",
  removeAll: "全て削除",
  close: "閉じる",
  execute: "実行",
  executeLong: "実行する",
  register: "登録",
  cease: "中止",
  cancel: "キャンセル",
  recreate: "再作成",
  search: "検索",
  searchLong: "検索する",
  filterBy: "絞り込み",
  displayMap: "地図表示",
  done: "手配完了",
  createReservationRecord: "予約記録作成",
  establishTheSale: "売り上げ確定する",
  returnThis: "返品する",
  backToList: "◁ 一覧に戻る",
  addMessages: "◁ メッセージを追加",
  reserve: "予約する",
  reserveInfoOnly: "予約情報だけ作成",
  reserveFlight: "フライトを予約する",
  reserveHotel: "ホテルを予約する",
  changeSearchMode: "検索モード切替",
  refundPartial: "一部返金",
  setAutoReply: "自動応答にする",
  showDetails: "詳細を表示する",
  hideDetails: "詳細を閉じる",
  origin: "出発地",
  destination: "目的地",
  schedule: "日程",
  people: "人数",
  itineraries: "周遊",
  hideMemo: "メモを隠す",
  showMemo: "メモを表示",
  searchHotel: "ホテルを検索",
  searchNextHotel: "次のホテルを検索",
  createConsultation: "新規作成",
  updateConsultationCondition: "更新",
  addConsultationCondition: "追加で新規作成",
  removeLong: "削除する",
  detail: "詳細",
  researchHotel: "ホテル再検索",
  researchFlight: "フライト再検索",
  correctPrice: "支払登録",
  commissionBack: "コミッションバック",
  restoreReservationForm: "予約フォームを復元",
  displayLeft: "左に表示",
  displayCenter: "中央に表示",
  displayRight: "右に表示",
  addFirst: "先頭に追加する",
  addFirstShort: "先頭に追加",
  setReminder: "リマインダー設定",
  setScheduledSending: "時間指定送信設定",
  disableHearingMode: "解除する",
  carousel: "カルーセル",
  text: "テキスト",
  createMessage: "メッセージを作成する",
  createChargeInfoMessage: "請求メッセージ作成",
  createTicketMessage: "チケットメッセージ作成",
  createPlanSummary: "プラン概要を作成する",
  getAutoSuggestion: "自動で選択",
  abortReservation: "予約を中止する",
  creationOfReservationInfo: "予約情報作成機能",
  addQuickReplyItem: "クイック返信アイテムを追加する",
  createPreReservationMessage: "予約詳細メッセージを作成",
  searchByDate: "日付指定検索",
  searchByPeriod: "期間内最安検索",
  switchNormalLabeledDataSet: "送信用に切り替え",
  switchMemoLabeledDataSet: "メモ用に切り替え",
  recreateCarousels: "同一プランの自動選択",
  void: "発券キャンセル",
  ticketing: "発券依頼中",
  waitingForTicketed: "発券待ち",
  ticketed: "発券済み",
  confirmed: "予約確定済み",
  confirming: "予約確定予定",
  refunding: "返金依頼中",
  refunded: "返金済み",
  arranged: "仮予約中",
  invoiced: "請求済み",
  waitingForArrangement: "手配待ち",
  waitingForConfirmation: "催行待ち",
  bulkAction: "一括処理",
  adjustPrice: "料金変更",
  addPrice: "料金追加",
  addRefund: "返金登録",
  changeDeadline: "期限変更",
  addTraveler: "+ 旅行者追加",
  removeTraveler: "- 旅行者削除",
  preReserve: "仮予約する",
  confirmReservation: "予約確定する",
  sendEmail: "メールを送信する",
  recreateByOneArrangement: "手配を一つだけ選んで再作成",
  selectWithDate: "日付を指定して選択",
  addAction: "アクションを追加",
  removeAction: "アクションを削除",
  addCarousel: "カルーセルを追加",
  removeCarousel: "カルーセルを削除",
  removeThisMessageGroup: "このメッセージグループを削除",
  updateThisMessageGroup: "このメッセージグループを更新",
  addMessageGroup: "メッセージグループを追加",
  removeMessageGroup: "メッセージグループを削除",
  updateMessageGroup: "メッセージグループを更新",
  createNew: "新規作成",
  addReply: "返信を追加",
  removeReply: "返信を削除",
  addKeyword: "キーワードを追加",
  removeMessage: "メッセージを削除",
  publishScenario: "シナリオを公開する",
  closeScenario: "シナリオを非公開にする",
  inputActionData: "アクションデータを手動入力",
  searchOptionalTour: "現地ツアー検索",
  reset: "リセット",
  duplicate: "複製",
  addCondition: "条件を追加",
  removeCondition: "条件を削除",
  addCrmMessageSchedule: "CRMメッセージスケジュールを追加",
  updateCrmMessageSchedule: "CRMメッセージスケジュールを更新",
  moreActions: "その他の操作",
  sendTestMessages: "テストメッセージを送信",
  registerPassport: "パスポート登録",
  addBasicInfo: "基本情報を追加",
  addTour: "ツアーを追加",
  registerNew: "新規追加",
  change: "変更する",
  delete: "削除する",
  publish: "公開する",
  saveDraft: "下書き保存",
  changeToDraft: "下書きに変更",
  schedulePost: "予約する",
};
