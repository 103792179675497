export const gmo: any = {
  errors: {
    100: "カード番号が指定されていません",
    101: "カード番号に数字以外の文字が含まれています",
    102: "カード番号が10桁〜16桁の範囲ではありません",
    110: "有効期限が指定されていません",
    111: "有効期限に数字以外の文字以外の文字が含まれています",
    112: "有効期限が4桁または6桁ではありません",
    113: "有効期限の月が正しくありません",
    121: "セキュリティコードの書式が正しくありません",
    122: "セキュリティコードの書式が正しくありません",
    901: "クレジット決済が現在できません。時間を置いて実行してください",
    default:
      "Credit card transaction could not be completed. Please check your card and try again.",
  },
};
