export const carouselFlight: any = {
  aboutUsingLcc: "About using LCC",
  lccNoteForPrice: `Pricing is cheap due to tough conditions such as
    [Advance seat selection] is charged
    [Deposit Baggage] is charged
    Purchase in-flight meals
    No cancellation after reservation`,
  lccNoteForPriceChange: `In addition, please understand beforehand that the fee may fluctuate depending \
  on the timing of payment due to changes in the remaining seat status, the end of the sale period, and so on.`,
  lccNoteForPayment:
    "* In that case, I will refund the full amount, please pay the difference.",
  aboutShortLayoverRisk: `This covers the minimum transfer time required by the airport.
  Therefore, in the event of a delay, you will be assigned a regular flight free of charge, but if there are no seats available, you may be transferred to a flight the next day or later.
  Please note that in many cases, on the same day, flight transfers can only be handled by the airline counter at the local airport, and we are limited in our ability to assist you.
  There are no cases of missed connections during normal operations, but it is recommended to allow at least 2 hours for connections from outside the U.S. to the U.S., as there is an immigration check.`,
  ShortLayoverRisk: `Translation pending`,
  airlineNoticeTitle: "Notice regarding {{airline}}",
  airportNoticeTitle: "Notice regarding {{airport}}",
  departureArrivalNoticeTitle: "Notice of Departure and Arrival Time",
  earlyDepartureNotice: "Departure time is early.",
  lateArrivalNotice: "Arrival time is late.",
  usDomesticFlightsNoticeTitle: "Notice for Domestic Flights in the U.S.",
};
