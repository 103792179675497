export const hotelArrangementPlanPrice = {
  priceType: {
    baseRate: "客室料金",
    taxAndServiceFee: "税及びサービス料",
    extraPersonFee: "エキストラ料金",
    compensation: "補償",
    propertyFee: "施設料金",
    arrangementFee: "取扱料金",
  },
};
