export const common: any = {
  update: "更新する",
  create: "作成する",
  createNew: "新規作成",
  publish: "公開する",
  saveDraft: "下書き保存",
  changeToDraft: "下書きに変更",
  add: "追加",
  delete: "削除",
  clear: "クリア",
  embed: "埋め込む",
  dismiss: "閉じる",
  showMore: "もっと見る",
  research: "再検索する",
  closeMore: "閉じる",
  cancel: "キャンセル",
  reset: "リセット",
  reserve: "予約する",
  detail: "詳細",
  schedule: "予約する",
  showAll: "すべて表示",
  showLess: "一部のみ表示",
  logIn: "ログイン",
  lineAddFriend: "LINE 友だち追加する",
  travelConsultationOnLine: "LINEで旅行相談する",
  send: "送信する",
  showDetail: "詳細を表示",
  hideDetail: "詳細を隠す",
  select: "選択する",
};
