export const hotelArrangementPlan: any = {
  numberOfAdult: "{{number}} Adults",
  numberOfChildren: "{{number}} Children", // 1の時はchildのが良い
  status: {
    arranged: "Arranged",
    cancelled: "Cancelled",
  },
  mealType: {
    nothing: "meal nothing",
    breakfast: "breakfast",
    dinner: "dinner",
    both: "both",
  },
};
