export const scenario = {
  scenarioList: "シナリオ一覧",
  messageGroup: "メッセージグループ",
  scenarioName: "シナリオ名",
  keyword: "キーワード",
  keywordIndex: "キーワード {{index}}",
  addMessages: "メッセージを追加する",
  operation: "操作",
  triggerCondition: "発動条件",
  action: "アクション",
  carousel: "カルーセル",
  actionData: "アクションデータ",
};
