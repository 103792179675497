import { hotelReservation } from "./hotelReservation/ja";
import { hotelArrangement } from "./hotelArrangement/ja";
import { hotelArrangementPlanFee } from "./hotelArrangementPlanFee/ja";
import { hotelArrangementPlan } from "./hotelArrangementPlan/ja";
import { hotelArrangementPlanPrice } from "./hotelArrangementPlanPrice/ja";
import { flightArrangementTravelerPrice } from "./flightArrangementTravelerPrice/ja";
import { flightReservation } from "./flightReservation/ja";
import { flightArrangementPrice } from "./flighArrangementPrice/ja";
import { reservation } from "./reservation/ja";

export const modelJa: any = {
  hotelReservation,
  hotelArrangement,
  hotelArrangementPlanFee,
  hotelArrangementPlan,
  hotelArrangementPlanPrice,
  flightArrangementPrice,
  flightArrangementTravelerPrice,
  flightReservation,
  reservation,
};
