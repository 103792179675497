export const reservation = {
  numberOfRooms: "客室数 {{number}}室",
  roomIndex: "客室 {{index}}",
  firstName: "名(英字)",
  lastName: "姓(英字)",
  totalPrice: "合計料金",
  otherTaxesIncl: "含む税金",
  priceBreakdown: "料金内訳",
  hotelFee: "施設で支払う料金",
  email: "メールアドレス(半角英数字)",
  creditCardNo: "カード番号",
  creditCardCvc: "セキュリティコード",
  creditCardExpireMonth: "有効期限(月)",
  creditCardExpireYear: "有効期限(年)",
  phoneNumber: "電話番号(半角数字)",
  address: "住所",
  travelerIndex: "旅行者 {{index}}",
  primaryTraveler: "代表者",
  namePassportNotice:
    "パスポートに記載されているとおりに名前を入力してください",
  passportCountry: "パスポート発行国",
  dateOfBirth: "生年月日",
  gender: "性別",
  male: "男性",
  female: "女性",
  breakdown: "内訳",
  book: "予約する",
  additionalInformation: "追加情報",
  otherCharges: "その他の諸経費",
  passportExpirationDate: "パスポート有効期間満了日",
  passportNumber: "パスポート番号",
  nationalityCountryCode: "国籍",
  smokingRequest: "禁煙/喫煙 リクエスト",
  beddingRequest: "ベッドタイプ リクエスト",
  specialRequest: "特別リクエスト",
  cancellationPolicy: "キャンセル規定",
  confirmationDeadLine: "予約確定期限",
  reservationDate: "予約日",
  travelStartDate: "旅行開始日",
  travelEndDate: "旅行終了日",
  flightDepartureDate: "フライト出発日",
  flightArrivalDate: "フライト到着日",
  flightExternalServiceReservationId: "GDS予約番号",
  flightAirlineReservationId: "航空会社予約番号",
  hotelCheckInDate: "ホテルチェックイン日",
  hotelCheckOutDate: "ホテルチェックアウト日",
  hotelExternalServiceReservationId: "ホテルの旅程番号(外部)",
  hotelArrangementId: "ホテルの手配番号(内部)",
  chatUser: "チャットユーザー",
  chatUserId: "チャットユーザーID",
  chatUserName: "チャットユーザー名",
  minRangeDate: "最小の日付",
  maxRangeDate: "最大の日付",
  reservationSearch: "予約検索",
  paymentRelated: "入金関連",
  lastChargeDate: "最終請求日",
  lastPaymentDate: "最終入金日",
  hasPayment: "入金",
  hasAuthorizedPayment: "オーソリ中の入金",
  hasCharge: "請求",
};
