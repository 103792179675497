export const hotelReservationNew: any = {
  confirmButton: "CONFIRM CONTENTS",
  submitButton: "COMPLETE BOOKING",
  creditCardTitle: "Credit card Information",
  userInformationTitle: "User Information",
  importantNotice: "Important information about your booking",
  cancelPolicy:
    "{{roomName}} {{text}} " +
    "or no-shows are subject to a property fee equal to total amount paid for the reservation.",
  noCancelPolicy: "{{roomName}} is no cancellation",
  beddingRequestNotice:
    "* A request is made to the hotel, but there is no guarantee. " +
    "Please ask yourself to check it at the hotel check-in.",
  specialRequestNotice:
    "* Please fill in half-width alphanumeric characters. The hotel will be notified as is. (Ex) Extra bed 1",
  backToForm: "Back to form",
  hotelAgreementTitle: "Reservation notes",
  reservationFailureDescription:
    "May fail to book some of the rooms when request to book multiple room at the same time",
};
