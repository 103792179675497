export const chatUnit = {
  priority: {
    a: "高",
    b: "中",
    c: "低",
    d: "自動",
    all: "全て",
    initial: "未設定",
  },
  dealingStep: {
    flight: "フライト",
    hotelTour: "ホテル + 観光",
    support: "質問対応",
    remind: "リマインド",
    all: "全て",
    initial: "未設定",
  },
  dealingStatus: {
    initialWorkRequest: "初期依頼",
    initialWorking: "初期対応",
    workRequest: "依頼",
    regularMemberWorkRequest: "社員依頼",
    priorityWorkRequest: "優先依頼",
    multiCityRequest: "周遊依頼",
    working: "対応中",
    autoWorkRequest: "自動依頼",
    autoWorking: "自動対応中",
    checkRequest: "確認依頼",
    checking: "確認対応中",
    modifyRequest: "修正依頼",
    modifying: "修正対応中",
    waiting: "回答待ち",
    initial: "対応不要",
    request: "依頼",
    all: "全て",
  },
  dealingStatusCategory: {
    all: "全て",
    working: "対応中",
    request: "依頼",
    initialWorkRequest: "初期依頼",
  },
  dealingStatusSubCategory: {
    all: "全て",
    check: "確認",
    regularMember: "社員",
    priority: "優先",
    auto: "自動",
    multiCity: "周遊",
  },
  continent: {
    asia: "アジア",
    north_and_south_america: "北南米",
    europe: "欧州",
    other: "その他",
    all: "全て",
  },
  dealingStepShorthand: {
    flight: "フラ",
    hotelTour: "ホ観",
    support: "質問",
    remind: "追客",
  },
  dealingStatusShorthand: {
    initialWorkRequest: "初期依頼",
    initialWorking: "初対",
    workRequest: "依頼",
    regularMemberWorkRequest: "社員依頼",
    priorityWorkRequest: "優先依頼",
    multiCityRequest: "周遊依頼",
    working: "対中",
    autoWorkRequest: "自動依頼",
    autoWorking: "自動対中",
    checkRequest: "確頼",
    checking: "確中",
    modifyRequest: "修頼",
    modifying: "修中",
    waiting: "回待",
  },
  reservationStatus: {
    reserved: "予約済",
    all: "全て",
  },
  blocked: {
    blocked: "ブロック済",
    none: "なし",
  },
  reserved: "予約済",
};
