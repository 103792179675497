export const insuranceArrangement = {
  status: {
    created: "作成済",
    arranged: "手配済",
    cancelled: "キャンセル済",
  },
  arrangementType: {
    tabiho: "tabiho",
  },
};
