export const formError: any = {
  flightReservationTravelers: "旅行者 {{index}}",
  roomIndex: "客室 {{index}}",
  firstName: "名",
  lastName: "姓",
  name: "氏名",
  email: "メールアドレス",
  cardNo: "カード番号",
  cvc: "セキュリティコード",
  expireMonth: "カード有効期限(月)",
  expireYear: "カード有効期限(年)",
  phoneNumber: "電話番号",
  address: "住所",
  passportCountry: "パスポート発行国",
  dateOfBirth: "生年月日",
  birthday: "生年月日",
  birthdayYear: "生年月日(年)",
  birthdayMonth: "生年月日(月)",
  birthdayDay: "生年月日(日)",
  sex: "性別",
  passportExpirationDate: "パスポート有効期間満了日",
  passportExpirationYear: "パスポート有効期間(年)",
  passportExpirationMonth: "パスポート有効期間(月)",
  passportExpirationDay: "パスポート有効期間(日)",
  passportNumber: "パスポート番号",
  nationality: "国籍",
  flightReservationFailureAgreement: "注意事項への同意",
  reservationTravelers: "搭乗者 {{index}}",
  guests: "客室 {{index}}",
};
