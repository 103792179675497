export const hotelReservationShow: any = {
  hotelOverview: "Hotel Overview",
  cancellationPenalty: "Cancellation penalty of {{fee}} will occur",
  noCancellation: "No Cancellations",
  cancelThisRoom: "Cancel this room",
  customer: "Customers",
  roomType: "Room type",
  planContent: "Plan contents",
  guestName: "Guest name",
  room: "Rooms",
  hotelRoom: "Hotel rooms",
  hotelPolicy: "Hotel policy",
  priceSummary: "Price Summary",
  regulation: "Rules & Restrictions",
  help: "Help",
};
