export const flightArrangementPrice = {
  priceType: {
    taxes: "Taxes",
    bookingDistributionFee: "Booking distribution fee",
    distributionFee: "Distribution fee",
    roundingAdjustment: "Rounding adjustment",
    creditCardSurcharge: "Credit card surcharge",
    luggageSurcharge: "Luggage surcharge",
    comboServiceFee: "Combo service fee",
    bankConversionCharge: "Bank conversion charge",
    bankHandlingCharge: "Bank handling charge",
    reservedSeatsIncludedSurcharge: "Seat reservation surcharge",
    otherTaxes: "Other taxes",
    baseFare: "Flight",
    taxAndSurcharge: "Taxes / Fuel Surcharge",
    arrangeFee: "Handling Charge",
    baggage: "Baggage",
    itinerary0Baggage: "Outward baggage",
    itinerary1Baggage: "Return baggage",
  },
};
