export const searchColumnType = {
  id: "id",
  name: "名称",
  places: "地名",
  address: "住所",
  tags: "タグ",
  tripadvisorUrl: "TripAdvisor",
  tabelogUrl: "食べログ",
  introductions: "紹介文",
  genres: "ジャンル",
  tripadvisorRating: "評価",
  totalTravelkoNum: "合計トラベルコ紐付け数",
  totalExpediaNum: "合計expedia紐付け数",
  totalBookingComNum: "合計booking.com紐付け数",
  enabledTravelkoNum: "有効なトラベルコ紐付け数",
  disabledTravelkoNum: "無効なトラベルコ紐付け数",
  disabledIntroductionNum: "未承認紹介文数",
  introductionNum: "合計紹介文数",
  disabledTagNum: "未承認タグ数",
  tagNum: "合計タグ数",
};
