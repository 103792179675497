export const hotelReservationShow: any = {
  hotelOverview: "ホテルの概要",
  cancellationPenalty: "{{fee}}のキャンセルペナルティが発生します",
  noCancellation: "キャンセル不可",
  cancelThisRoom: "この客室をキャンセルする",
  customer: "お客さま",
  roomType: "部屋タイプ",
  planContent: "含まれているプラン内容",
  guestName: "ご予約者名",
  room: "客室",
  hotelRoom: "ホテルの客室",
  hotelPolicy: "ホテルのポリシー",
  priceSummary: "料金の概要",
  regulation: "規則と制限事項",
  help: "ヘルプ",
};
