export const optionalTourArrangement = {
  status: {
    created: "作成済",
    invoiced: "請求済み",
    arranged: "手配済",
    cancelled: "キャンセル済",
    waiting_for_confirmation: "催行待ち",
    ticketed: "発券済み",
    confirmed: "予約確定",
    refunding: "返金依頼中",
    refunded: "返金済み",
  },
  arrangementType: {
    veltra: "VELTRA",
    other: "その他",
  },
  initialCancelPolicy: "▼ 規定について ▼\n",
};
