export const hotelArrangementPlanPrice = {
  priceType: {
    baseRate: "Room Price",
    taxAndServiceFee: "Taxes & Fees",
    extraPersonFee: "Extra Person Fee",
    compensation: "Compensation",
    propertyFee: "Property Fee",
    arrangementFee: "Handling Charge",
  },
};
