export const airportCode = {
  PVG: "上海 (PVG-浦東空港)",
  PEK: "北京 (PEK-首都空港)",
  SZX: "深圳 (SZX-深圳空港)",
  HKG: "香港 (HKG-香港国際空港)",
  FOC: "福州 (FOC-福州長楽国際空港)",
  SHA: "上海 (SHA-紅橋空港)",
  CTU: "成都 (CTU-成都空港)",
  DLC: "大連 (DLC-大連空港)",
  XMN: "厦門 (XMN-厦門空港)",
  WUH: "武漢 (WUH-武漢国際空港)",
  CAN: "広州 (CAN-白雲空港)",
};
