export const modal: any = {
  searchExpirationTitle: "Check the latest information",
  searchExpirationDescription:
    "In order to display the latest information, you need to update the search results.",
  searchRefresh: "Update",
  reservationExpirationTitle: "Session Expired",
  reservationExpirationDescription:
    "This reservation session has expired. Please search again.",
  reservationResearch: "Search again",
  reservationOutOfStockTitle: "Out of stock",
  reservationOutOfStockDescription:
    "This item is sold out. Please search again.",
  reservationPriceChangedTitle: "Price changed",
  reservationPriceChangedDescription:
    "The price of this item has changed. You need to update this page.",
  reservationRefresh: "Update",
  selectionTitle: "The {{type}} has been selected",
  selectionDescription:
    "We will contact you shortly after confirmation. Please wait a moment",
  selectionEmailDescription:
    "You will later receive an email with your selections. Please reply if you would like to receive another proposal from the concierge.",
  selectionWitCostDescription: "The total price has been sent to chat.",
  reservationAllFailedTitle: "Reservation failed",
  reservationAllFailedDescription:
    "Could not make a reservation. Please search again.",
  reservationFlightPartiallyFailedTitle: "Some flights could not be booked",
  reservationFlightPartialFailedDescription:
    "Some of the multiple flight bookings failed. " +
    "The reserved flight is non-cancellable and the fee for the failed flight will be refunded.",
  reservationCommitFailedTitle: "Booking could not be completed.",
  reservationCommitFailedDescription:
    "Please correct your input and try again to make a reservation.",
  continue: "Continue",
};
