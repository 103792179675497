export const scenario = {
  scenarioList: "Scenario List",
  messageGroup: "Message group",
  scenarioName: "Scenario name",
  keyword: "Keywords",
  keywordIndex: "Keyword {{index}}",
  addMessages: "Add messages",
  operation: "Operation",
  triggerCondition: "Trigger conditions",
  action: "Action",
  carousel: "Carousel",
  actionData: "Action data",
};
