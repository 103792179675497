export const chatUnit = {
  priority: {
    a: "A",
    b: "B",
    c: "C",
    d: "AUTO",
    all: "ALL",
    initial: "NOT SET",
  },
  dealingStep: {
    flight: "FLIGHT",
    hotelTour: "HOTEL TOUR",
    support: "SUPPORT",
    remind: "REMIND",
    all: "ALL",
    initial: "NOT SET",
  },
  dealingStatus: {
    initialWorkRequest: "INITIAL WORK REQUEST",
    initialWorking: "INITIAL WORKING",
    workRequest: "WORK REQUEST",
    regularMemberWorkRequest: "REGULAR MEMBER REQUEST",
    priorityWorkRequest: "PRIORITY REQUEST",
    working: "WORKING",
    autoWorkRequest: "AUTO REQUEST",
    autoWorking: "AUTO WORKING",
    checkRequest: "CHECK REQUEST",
    checking: "CHECKING",
    modifyRequest: "MODIFY REQUEST",
    modifying: "MODIFYING",
    initial: "NO ACTION NEEDED",
    request: "REQUEST",
    all: "ALL",
  },
  dealingStatusCategory: {
    all: "ALL",
    working: "WORKING",
    request: "REQUEST",
    initialWorkRequest: "INITIAL WORK REQUEST",
  },
  dealingStatusSubCategory: {
    all: "ALL",
    check: "CHECK",
    regularMember: "REGULAR MEMBER",
    priority: "PRIORITY",
    auto: "AUTO",
    multiCity: "MULTI CITY",
  },
  continent: {
    asia: "ASIA",
    north_and_south_america: "N&S AMERICA",
    europe: "EUROPE",
    other: "OTHER",
    all: "ALL",
  },
  dealingStepShorthand: {
    flight: "Flight",
    hotelTour: "Hotel&Tour",
    support: "Support",
    remind: "Remind",
  },
  dealingStatusShorthand: {
    initialWorkRequest: "Initial W.R",
    initialWorking: "Initial W",
    workRequest: "WR",
    regularMemberWorkRequest: "RM W.R",
    priorityWorkRequest: "Priority W.R",
    working: "W",
    autoWorkRequest: "Auto W.R",
    autoWorking: "Auto W",
    checkRequest: "Check R",
    checking: "Checking",
    modifyRequest: "Modify R",
    modifying: "Modifying",
  },
  reservationStatus: {
    reserved: "RESERVED",
    all: "ALL",
  },
  blocked: {
    blocked: "BLOCKED",
    none: "NONE",
  },
  reserved: "Reserved",
};
