export const wifiArrangement = {
  status: {
    created: "created",
    arranged: "arranged",
    cancelled: "cancelled",
  },
  arrangementType: {
    global: "global",
  },
};
