import { chat } from "./chat/en";
import { common } from "./common/en";
import { hotel } from "./hotel/en";
import { scenario } from "./scenario/en";
import { crmMessageSchedule } from "./crmMessageSchedule/en";
import { en as inHouseNotice } from "./inHouseNotice/en.yml";
import { en as lp } from "./lp/en.yml";
import { en as emailChatUnit } from "./emailChatUnit/en.yml";
import { en as notification } from "./notification/en.yml";
import { en as cms } from "./cms/en.yml";

export const componentEn = {
  chat,
  common,
  hotel,
  scenario,
  crmMessageSchedule,
  inHouseNotice,
  lp,
  emailChatUnit,
  notification,
  cms,
};
