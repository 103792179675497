export const airportCode = {
  PVG: "Shanghai (PVG-Shanghai Pudong International Airport)",
  PEK: "Beijing (PEK-Beijing Capital International Airport)",
  SZX: "Shenzhen (SZX-Shenzhen Bao'an International Airport)",
  HKG: "Hong Kong (HKG-Hong Kong International Airport)",
  FOC: "Fuzhou (FOC-Fuzhou Changle International Airport)",
  SHA: "Shanghai (SHA-Shanghai Hongqiao International Airport)",
  CTU: "Chengdu (CTU-Chengdu Shuangliu International Airport)",
  DLC: "Dalian (DLC-Dalian Zhoushuizi International Airport)",
  XMN: "Xiamen (XMN-Xiamen Gaoqi International Airport)",
  WUH: "Wuhan (WUH-Wuhan Tianhe International Airport)",
  CAN: "Guangzhou (CAN-Guangzhou Baiyun International Airport)",
};
