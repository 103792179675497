export const hotelArrangement = {
  status: {
    created: "created",
    arranged: "arranged",
    cancelled: "cancelled",
  },
  arrangementType: {
    unknown: "unknown",
    taap: "TAAP",
    rikisha: "rikisha",
    expedia: "Expedia",
    hotel_site: "Hotel Site",
    rka: "Rikisha API",
  },
  stayNum: "{{stayNum}} Night",
};
