import { common } from "./common/ja";
import { payment } from "./payment/en";
import { charge } from "./charge/en";
import { reservation } from "./reservation/en";
import { hotelArrangement } from "./hotelArrangement/en";
import { hotelArrangementPlan } from "./hotelArrangementPlan/en";
import { flightArrangement } from "./flightArrangement/en";
import { flightArrangementTraveler } from "./flightArrangementTraveler/en";
import { flightArrangementTravelerOption } from "./flightArrangementTravelerOption/en";
import { flightArrangementOption } from "./flightArrangementOption/en";
import { flightArrangementPrice } from "./flightArrangementPrice/en";
import { chatUnit } from "./chatUnit/en";
import { optionalTourArrangement } from "./optionalTourArrangement/en";
import { rentalCarArrangement } from "./rentalCarArrangement/en";
import { wifiArrangement } from "./wifiArrangement/en";
import { railwayArrangement } from "./railwayArrangement/en";
import { cruiseArrangement } from "./cruiseArrangement/en";
import { insuranceArrangement } from "./insuranceArrangement/en";
import { searchInput } from "./searchInput/en";
import { masterMessage } from "./masterMessage/en";
import { hotelArrangementPlanPrice } from "./hotelArrangementPlanPrice/en";
import { hotelArrangementPlanFee } from "./hotelArrangementPlanFee/en";
import { otherArrangement } from "./otherArrangement/en";
import { discount } from "./discount/en";
import { delayBatchEvent } from "./delayBatchEvent/en";
import { scenario } from "./scenario/en";
import { crmMessageSchedule } from "./crmMessageSchedule/en";
import { masterMessageGroup } from "./masterMessageGroup/en";

export const modelEn = {
  common,
  payment,
  charge,
  reservation,
  hotelArrangement,
  hotelArrangementPlan,
  flightArrangement,
  flightArrangementTraveler,
  flightArrangementTravelerOption,
  flightArrangementOption,
  flightArrangementPrice,
  optionalTourArrangement,
  rentalCarArrangement,
  wifiArrangement,
  railwayArrangement,
  cruiseArrangement,
  insuranceArrangement,
  chatUnit,
  searchInput,
  masterMessage,
  hotelArrangementPlanPrice,
  hotelArrangementPlanFee,
  otherArrangement,
  discount,
  delayBatchEvent,
  scenario,
  crmMessageSchedule,
  masterMessageGroup,
};
