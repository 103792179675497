export const scenario = {
  status: {
    published: "publish",
    close: "close",
  },
  chatUnitStatus: {
    hearingDestination: "【Hearing】Destination",
    hearingOrigin: "【Hearing】Origin",
    hearingSchedule: "【Hearing】Schedule",
    hearingPeople: "【Hearing】Number of people",
    hearingPricePerPerson: "【Hearing】Price per person",
    hearing_hope: "【Hearing】Hope",
  },
};
