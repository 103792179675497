export const hotelArrangementPlan: any = {
  numberOfAdult: "大人{{number}}人",
  numberOfChildren: "子供{{number}}人",
  status: {
    arranged: "手配済",
    cancelled: "キャンセル済",
  },
  mealType: {
    nothing: "食事なし",
    breakfast: "朝食付き",
    dinner: "夕食付き",
    both: "朝・夕付き",
    breakfast_and_dinner: "朝・夕付き",
  },
};
