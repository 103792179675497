export const flightReservationNew: any = {
  confirmButton: "申し込み内容を確認する",
  submitButton: "予約を完了する",
  creditCardTitle: "クレジットカードの情報",
  userInformationTitle: "お客様の情報",
  flightAgreementTitle: "予約に関する注意事項",
  importantNotice: "予約に関する重要なお知らせ",
  // cancelPolicy: '{{roomName}}は{{datetime}}(現地時間) を過ぎてから行われたキャンセルや変更、または不泊の場合は、{{price}}円 の手数料が課金されます。',
  contactNotice: "予約後の連絡について",
  flightConnecting: "乗り継ぎ",
  noCancellationOrNoData: "キャンセル不可または情報がありませんでした。",
  refundableCancellationPolicy: "出発前まで一部返金可能。",
  noCancellation: "キャンセル不可",
  cancellable: "キャンセル可能",
  cancellationFee: "キャンセル料",
  totalRefundAmount: "合計返金額",
  arrangementIndex: "手配 {{index}}：",
  reservationFailureDescription:
    "複数のフライト手配を行うため、一部の経路の予約に失敗することがあります。",
  backToForm: "戻って内容を修正する",
  flightReservation: "フライトの予約",
  flightReservationConfirmation: "フライト予約内容の確認",
  confirmNotice:
    'まだ予約は完了しておりません。内容をご確認の上、画面下部の"予約を完了する"ボタンを押してください。',
};
