export const modal: any = {
  searchExpirationTitle: "最新情報をチェック",
  searchExpirationDescription:
    "最新の情報を表示するため、検索結果を更新する必要があります。",
  searchRefresh: "更新する",
  reservationExpirationTitle: "セッションの有効期限切れ",
  reservationExpirationDescription:
    "この予約は有効期限切れになりました。再度検索してください。",
  reservationResearch: "再検索する",
  reservationOutOfStockTitle: "商品の在庫切れ",
  reservationOutOfStockDescription:
    "この商品は完売になりました。再度検索してください。",
  reservationPriceChangedTitle: "料金が変更になりました",
  reservationPriceChangedDescription:
    "この商品の料金が変更になったため、ページを更新する必要があります。",
  reservationRefresh: "更新する",
  selectionTitle: "{{type}}が選択されました",
  selectionDescription:
    "内容を確認でき次第、折り返しご連絡させていただきます。しばらくお待ちください。",
  selectionEmailDescription:
    "後ほど選択された内容がメールで送信されますので、コンシェルジュからの再提案をご希望の場合はご返信ください。",
  selectionWitCostDescription: "合計料金をチャットにお送りいたしました。",
  reservationAllFailedTitle: "予約に失敗しました",
  reservationAllFailedDescription:
    "予約することができませんでした。再度検索してください。",
  reservationFlightPartiallyFailedTitle: "一部のフライトを予約できませんでした",
  reservationFlightPartialFailedDescription:
    "複数のフライト予約のうち一部で失敗になりました。" +
    "予約済みのフライトはキャンセル不可のため、予約に失敗したフライト分の料金が返金されます。",
  reservationCommitFailedTitle: "予約が完了できませんでした",
  reservationCommitFailedDescription:
    "入力を修正して再度予約を実行してください",
  continue: "続ける",
};
