export const post: any = {
  indexTitle: "Index",
  popularTags: "Popular Tags",
  rankingList: "Popular Posts",
  relatedPosts: "Related Posts",
  free: "FREE",
  allRegion: "Show all regions",
  hotelSearch: "Hotel Search",
  flightSearch: "Flight Search",
  dynamicPackageSearch: "Flight + Hotel Search",
  bestPriceSearch: "Best Price",
};
