export const railwayArrangement = {
  status: {
    created: "作成済",
    invoiced: "請求済み",
    arranged: "手配済",
    cancelled: "キャンセル済",
    waiting_for_arrangement: "手配待ち",
    confirmed: "予約確定",
    refunding: "返金依頼中",
    refunded: "返金済",
  },
  arrangementType: {
    tumlare: "tumlare",
    go_euro: "go_euro",
    omio: "omio",
  },
  initialCancelPolicy: "入金後の変更・返金不可です",
};
