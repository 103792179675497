export const form: any = {
  firstNamePlaceholder: "例) TARO",
  lastNamePlaceholder: "例) YAMADA",
  roomTitle: "旅行者の情報",
  travelerTitle: "旅行者の情報",
  guestTitle: "宿泊者の情報",
  passengerTitle: "搭乗者の情報",
  additionalTitle: "追加情報",
  submitFormError: "フォームでエラーが発生しました",
  submitError: "エラーが発生しました",
  passportNumberPlaceholder: "例) TX1234567",
  edit: "編集する",
  hotelSpecialRequestPlaceholder: "例) Extra bed 1",
  titlePlaceholder: "タイトルを入力してください",
  summaryPlaceholder: "概要を入力してください",
  tagPlaceholder: "タグを選択または入力してください",
  relatedPostsPlaceholder: "タイトルの一部を入力してください",
  lineFooterTextPlaceholder: "イギリス&フランス旅行を相談する",
};
