export const flightReservationNew: any = {
  confirmButton: "CONFIRM CONTENTS",
  submitButton: "COMPLETE BOOKING",
  creditCardTitle: "Credit card Information",
  userInformationTitle: "User Information",
  flightAgreementTitle: "Reservation notes",
  importantNotice: "Important information about your booking",
  contactNotice: "Contact information after booking",
  flightConnecting: "Connect",
  noCancellationOrNoData: "It can not be canceled or there was no information.",
  refundableCancellationPolicy: "Partial refundable before departure.",
  noCancellation: "No Cancellations",
  cancellable: "Cancellable",
  cancellationFee: "Cancellation fee",
  totalRefundAmount: "Total refund amount",
  arrangementIndex: "Arrangement {{index}}: ",
  reservationFailureDescription:
    "Some flights may fail to be booked due to multiple flight arrangements.",
  backToForm: "Back to form",
  flightReservation: "Flight reservation",
  flightReservationConfirmation: "Flight reservation confirmation",
  confirmNotice:
    "The reservation has not been completed yet. " +
    'After confirming the contents, click the "COMPLETE BOOKING" button at the bottom of the screen.',
};
