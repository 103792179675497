export const dynamicPackageReservationNew: any = {
  reservationTitle: "Flight and hotel booking",
  confirmButton: "CONFIRM CONTENTS",
  submitButton: "COMPLETE BOOKING",
  creditCardTitle: "■ Creditcard Information",
  userInformationTitle: "■ User Information",
  importantNotice: "Important information about your booking",
  cancelPolicy:
    "{{roomName}}: Cancellations or changes made after {{datetime}}(Local time) " +
    "or no-shows are subject to a property fee equal to {{price}}of the total amount paid for the reservation.",
  contactNotice: "Contact information after booking",
  flightConnecting: "Connect",
  flightPlan: "Flight plan",
  hotelPlan: "Hotel plan",
  backToForm: "Back to form",
  dynamicPackageAgreementTitle: "Reservation notes",
  reservationFailureDescription:
    "May fail to book some of the rooms when request to book multiple room at the same time." +
    " May fail to book part of the flights whe request to book multiple flight itineraries at the same time.",
};
