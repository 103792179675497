export const cityCode: any = {
  PAR: "Paris",
  LON: "London",
  ROM: "Rome",
  BCN: "Barcelona",
  NYC: "New York",
  SFO: "San Francisco",
  TPE: "Taipei",
  SEL: "Seoul",
  SIN: "Singapore",
  HKG: "Hong Kong",
  DPS: "Bali",
  BKK: "Bangkok",
};
