export const dynamicPackageReservationNew: any = {
  reservationTitle: "フライト+ホテルの予約",
  confirmButton: "申し込み内容を確認する",
  submitButton: "予約を完了する",
  creditCardTitle: "■ クレジットカードの情報",
  userInformationTitle: "■ お客様の情報",
  importantNotice: "予約に関する重要なお知らせ",
  cancelPolicy:
    "{{roomName}}は{{datetime}}(現地時間) を過ぎてから行われたキャンセルや変更、または不泊の場合は、{{price}}円 の手数料が課金されます。",
  contactNotice: "予約後の連絡について",
  flightConnecting: "乗り継ぎ",
  flightPlan: "フライトプラン",
  hotelPlan: "ホテルプラン",
  backToForm: "戻って内容を修正する",
  dynamicPackageAgreementTitle: "予約に関する注意事項",
  reservationFailureDescription:
    "複数のホテル手配を行うため、一部の部屋の予約に失敗することがあります。" +
    "複数のフライト手配を行うため、一部の経路の予約に失敗することがあります。",
};
