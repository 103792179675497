export const cityCode: any = {
  PAR: "パリ",
  LON: "ロンドン",
  ROM: "ローマ",
  BCN: "バルセロナ",
  NYC: "ニューヨーク",
  SFO: "サンフランシスコ",
  TPE: "台北",
  SEL: "ソウル",
  SIN: "シンガポール",
  HKG: "香港",
  DPS: "バリ島",
  BKK: "バンコク",
};
