export const flightArrangementTraveler = {
  status: {
    created: "作成済み",
    invoiced: "請求済み",
    arranged: "仮予約中",
    ticketing: "発券中",
    unconfirmed: "予約処理中・未確認",
    waitingForTicketed: "発券待ち",
    ticketed: "発券済み",
    void: "発券キャンセル済み",
    cancelled: "キャンセル済み",
    refunding: "返金依頼中",
    refunded: "返金済み",
    failed: "失敗",
  },
};
