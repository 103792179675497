export const consultationConditionEdit: any = {
  thankYouTitle: "ご相談ありがとうございました",
  thankYouMessage:
    "後ほどチャットにて返信させていただきますので\nしばらくお待ちください。",
  additionalConditionFormTitle: "追加のご希望について",
  additionalConditionFormDescription1:
    "ご希望にマッチしたフライト・ホテルをご案内します。",
  additionalConditionFormDescription2:
    "特にない場合は、オススメのプランをご提案します。",
  additionalConditionFormSubmitComplete:
    "送信完了いたしました\nありがとうございました",
  additionalConditionFormSubmit: "希望を送信する",
  regionSelectionNote: "選択しなければ便利な地域が選ばれます",
};
