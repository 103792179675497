export const hotelArrangementPlan = {
  status: {
    created: "created",
    invoiced: "invoiced",
    arranged: "arranged",
    cancelled: "cancelled",
    unconfirmed: "unconfirmed",
    confirmed: "confirmed",
    confirming: "confirming",
    refund: "refund",
    refunded: "refunded",
  },
  mealType: {
    nothing: "meal nothing",
    breakfast: "breakfast",
    dinner: "dinner",
    both: "breakfast & dinner",
    all_meals: "three meals",
  },
  payType: {
    pre_payment: "pre payment",
    on_site: "on site",
  },
};
