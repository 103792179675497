export const hotel: any = {
  age: "年齢",
  checkin: "チェックイン",
  checkout: "チェックアウト",
  checkinDate: "チェックイン日",
  checkoutDate: "チェックアウト日",
  destination: "目的地",
  hopeSeparateBed: "同一ベッド(ダブルベッド等)不可",
  separateBeds: "一人一台のベッド",
  mealRequest: "食事希望",
  nothing: "食事なし",
  breakfast: "朝食付き",
  dinner: "夕食付き",
  both: "朝・夕付き",
  viewType: "客室からの眺望",
  bedType: "ベッドタイプ",
  roomAmenity: "部屋の設備・備品",
  agreement: "上記の内容に同意する",
  minRating: "レビュー評価",
  regionGreat: "おすすめの地域",
};
