export const scenario = {
  status: {
    published: "公開",
    close: "非公開",
  },
  chatUnitStatus: {
    hearingDestination: "【ヒアリング】行き先",
    hearingOrigin: "【ヒアリング】出発地",
    hearingSchedule: "【ヒアリング】日程",
    hearingPeople: "【ヒアリング】人数",
    hearingPricePerPerson: "【ヒアリング】予算",
    hearingHope: "【ヒアリング】希望",
  },
};
