export const seo = {
  siteName: "TabiChat",
  titleChat: "Chat",
  titleChatUnit: "User Registration",
  titleMasterMessage: "Message Template Registration",
  titleMasterMessageGroups: "Message Groups",
  titleCrmMessageSchedules: "CRM Message Schedules",
  titleHotels: "Hotels",
  titleActivities: "Activities",
  titleRestaurants: "Restaurants",
  titleReservations: "Reservations",
  titleScenarios: "Scenarios",
  titleInHouseNotice: "In-house Notice",
  titleLpsIndex: "LP Contents List",
  titleLpsNew: "Create LP Contents",
  titleLpsEdit: "Edit LP Contents",
  titleEmailChatUnit: "Email User Registration",
};
