export const flightArrangementTravelerPrice: any = {
  priceType: {
    baseFare: "航空券",
    taxAndSurcharge: "税 / 燃油サーチャージなど",
    arrangeFee: "取扱料金",
    baggage: "預け入れ手荷物",
    itinerary0Baggage: "預け入れ手荷物(往路)",
    itinerary1Baggage: "預け入れ手荷物(復路)",
  },
};
