export const optionalTourArrangement = {
  status: {
    created: "created",
    invoiced: "invoiced",
    arranged: "arranged",
    cancelled: "cancelled",
    waiting_for_confirmation: "waiting for confirmation",
    ticketed: "ticketed",
    confirmed: "confirmed",
    refunding: "refunding",
    refunded: "refunded",
  },
  arrangementType: {
    veltra: "VELTRA",
    other: "other",
  },
  initialCancelPolicy: "▼ About policy ▼\n",
};
