export const hotel = {
  hopeSeparateBed: "Separate beds",
  separateBeds: "One bed per person",
  mealRequest: "Hotel meal",
  rankSelection: "Hotel rank",
  recommendedGenre: "Recommended genre",
  viewType: "View type",
  bedSizeType: "Room type",
  bedSizeTypes: "Bed type",
  bedCount: "Number of beds",
  specificHotel: "Specific hotel",
  selectedHotel: "Selected hotel",
  amenity: "Amenities",
  smokingTypes: "Smoking type",
  roomAmenities: "Room Amenities",
  hotelAmenities: "Hotel Amenities",
  categories: "Hotel Category",
  spokenLanguages: "Hotel Languages",
  rank: "Hotel Rank",
  minRating: "Review",
  existIntroduction: "Introduction Sentences",
  mealType: "Meal type",
  region: "Regions",
  hotelId: "Selected hotel",
};
