export const formError: any = {
  flightReservationTravelers: "Traveler {{index}}",
  roomIndex: "Room {{index}}",
  firstName: "First Name",
  lastName: "Last Name",
  name: "Name",
  email: "Email",
  cardNo: "Credit Card No",
  cvc: "Security Code",
  expireMonth: "Card Expire Month",
  expireYear: "Card Expire Year",
  phoneNumber: "Phone Number",
  address: "Address",
  passportCountry: "Passport issuing country",
  dateOfBirth: "Date of birth",
  birthday: "Date of birth",
  birthdayYear: "Date of birth(Year)",
  birthdayMonth: "Date of birth(Month)",
  birthdayDay: "Date of birth(Day)",
  sex: "Gender",
  passportExpirationDate: "Passport date of expiry",
  passportExpirationYear: "Passport date of expiry(Year)",
  passportExpirationMonth: "Passport date of expiry(Month",
  passportExpirationDay: "Passport date of expiry(Day)",
  passportNumber: "Passport number",
  nationality: "Nationality",
  flightReservationFailureAgreement: "Agree to the notes",
  reservationTravelers: "Traveler {{index}}",
  guests: "Room {{index}}",
};
