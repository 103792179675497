export const wifiArrangement = {
  status: {
    created: "作成済",
    arranged: "手配済",
    cancelled: "キャンセル済",
  },
  arrangementType: {
    global: "global",
  },
};
