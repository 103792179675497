export const input = {
  title: "Title：",
  image: "Image：",
  address: "Address：",
  introductionSentences: "Introduction Sentences：",
  freeWords: "Free Words",
  carouselSet: "Carousel Set",
  action: "Action",
  flight: "Flight",
  price: "Price: ",
  arrangeFee: "Fee: ",
  priceDisplay: "Price Display: ",
  yen: "YEN",
  placeholderSearchUser: "Search user by name or ID",
  placeholderImageUrl: "Input image url...",
  placeholderText: "Input text...",
  placeholderMemo: "Input memo...",
  placeholderRecommended: "Input recommended sentence...",
  placeholderInput: "Please input...",
  placeholderDestination: "Please input any destinations...",
  placeholderQuery: "Please input a keyword...",
  placeholderFlightCityOrAirport: "City name  or  Airport name",
  placeholderDate: "years / months / days",
  placeholderNameOrAddressOrUrl: "Please input any names or address or URL...",
  placeholderHope: "Input hope...",
  placeholderQuickReplyActionData: "action=action_data&action_id=1",
  placeholderQuickReplyActionLabel: "Label",
  placeholderQuickReplyActionDisplayText: "Display Text",
  placeholderHotelSelection: "Input hotel name...",
  placeholderAirlineSelection: "Input airline name...",
  placeholderSpecialRequest: "e.g. Extra bed 1",
  placeholderEmail: "Input email address...",
  placeholderScheduleName: "Input schedule name...",
  placeholderLineType: "Input LINE type...",
};
