export const masterMessage = {
  platformType: {
    all_platform: "All Platform",
    line: "LINE",
    facebook: "Facebook",
  },
  groupType: {
    greeting: "Greeting",
    hearing: "Hearing",
    proposal_starting: "Proposal starting",
    closing: "Reservation",
    mine: "Mine",
    bot_initial_greeting: "Initial greeting",
    bot_remind_hearing: "Hearing reminder",
    bot_remind_initial_action: "Initial action reminder",
  },
};
