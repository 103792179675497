export const post: any = {
  indexTitle: "目次",
  popularTags: "人気のタグ",
  rankingList: "人気の記事",
  relatedPosts: "関連記事",
  free: "無料",
  allRegion: "全ての地域を表示する",
  hotelSearch: "ホテル検索",
  flightSearch: "フライト検索",
  dynamicPackageSearch: "フライト+ホテル検索",
  bestPriceSearch: "最安検索",
};
