import { reservation } from "./reservation/ja";
import { common } from "./common/ja";
import { consultationCondition } from "./consultationCondition/ja";
import { flight } from "./flight/ja";
import { hotel } from "./hotel/ja";
import { formError } from "./formError/ja";
import { cms } from "./cms/ja";
import { ja as form } from "./form/ja.yml";
import { ja as emailUser } from "./emailUser/ja.yml";
import { ja as consultation } from "./consultation/ja.yml";

export const labelJa: any = {
  reservation,
  common,
  consultationCondition,
  flight,
  hotel,
  formError,
  cms,
  form,
  emailUser,
  consultation,
};
