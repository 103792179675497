export const hotelReservationNew: any = {
  confirmButton: "申し込み内容を確認する",
  submitButton: "予約を完了する",
  creditCardTitle: "クレジットカードの情報",
  userInformationTitle: "お客様の情報",
  importantNotice: "予約に関する重要なお知らせ",
  cancelPolicy: "{{roomName}}は{{text}}",
  noCancelPolicy: "{{roomName}}はキャンセル不可です。",
  beddingRequestNotice:
    "※ ホテルへリクエストいたしますが確約ではございません。ホテルチェックイン時にお客様自身でご確認いただくようお伝えください。",
  specialRequestNotice:
    "※ 半角英数字（日本語不可）でご記入ください。ホテルにそのまま通知されます。 (Ex) Extra bed 1",
  backToForm: "戻って内容を修正する",
  hotelAgreementTitle: "予約に関する注意事項",
  reservationFailureDescription:
    "複数のホテル手配を行うため、一部の部屋の予約に失敗することがあります。",
};
