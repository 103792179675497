export const messageEn = {
  hotelText: `┏━┓
┃田┣━┓TabiChat
┃田┃田┃recommended
┃田┃田┃hotels
¬¬¬¬¬¬¬¬¬¬¬¬¬¬¬
●{{name}}
¬¬¬¬¬¬¬¬¬¬¬¬¬¬¬
Address：{{address}}

{{value}}

{{freeText}}`,
  roomCountText: " x {{roomCount}} rooms",
  roomsLeftText: `【Only {{roomsLeft}} rooms left】\n`,
  roomIndex: `Room {{index}}：`,
  numberOfAdultsText: `{{numberOfAdult}} adults,`,
  numberOfChildrenText: ` {{numberOfChildren}} children`,
  hotelFreeText: `◆◆━━━━━━━━
◆ Hotel Plan Introduction
━━━━━━━━━
Dates：{{checkinDate}}〜{{checkoutDate}}
Guests：{{generateGuestsText}}
Meals：{{meals}}
Rooms：{{roomName}}{{roomCountText}}

Total Price：{{inclusiveTotalPrice}}
（{{inclusivePricePerDayPerRoom}}/ a day a room）
---
{{freeCancellationText}}
{{planFeesText}}
{{roomsLeftText}}`,
  noCancellations: "No cancellations",
  cancellationFree:
    "There is no cancellation charge until {{costCancellationChargeDate}}",
  totalPrice: "\nTotalPrice：{{totalPrice}}",
  flightDetailText: `📍{{departureAirportName}}
{{departureDate}} {{departureTime}}

↓ ✈️ {{airlineName}}

{{arrivalDate}} {{arrivalTime}}{{deltaDate}}
📍{{arrivalAirportName}}`,
  stopover: `- - - - - - - - - - - - - - -
  Transit：{{layover}}
- - - - - - - - - - - - - - -
`,
  deltaDate: "(+{{deltaDate}}day)",
  flightItineraryText: `
▼ Itinerary{{index}}:【{{duration}}】
{{flightDetailsText}}
`,
  flightText: `✈️ Flight details
{{flightItinerariesText}}

👜 【Free】Baggage provision
━━━━━━━━━
{{handBaggageText}}
{{additionalBaggageText}}

{{priceText}}`,
  quantityFreeBaggagePerPerson:
    "Free baggage allowance: up to {{quantity}} piece(s)/person",
  weightFreeBaggagePerPerson:
    "Free baggage allowance: up to {{weight}}kg(s)/person",
  quantityWeightFreeBaggagePerPerson:
    "Free baggage allowance: up to {{quantity}} ({{weight}}{{weightUnit}}(s)) piece(s)/person",
  noFreeBaggage: "No free baggage included",
  noFreeBaggageInformation: "Please Ask Because No information",
  additionalBaggage:
    "\n👜 【Paid】Baggage provision\n━━━━━━━━━\n{{additionalBaggageText}}",
  baggageWithItinerary: "Itinerary{{index}}: {{baggageText}}",
  hotelRecommendedSentenceMessage:
    "🏨 Recommended Hotel\n━━━━━━━━━\n{{freeText}}\n{{sentencesText}}\n\n▼▼ The details were written below. ▼▼",
  actionInitText: "👉 Look at in detail 👈",
  none: "none",
  messageButtonText: `<Reservation Flow>
⑴ Input reservation form
⑵ Reach confirmation message 
⑶ To payment`,
  clickHereForReservation: "👉 Click here for reservation 👈",
  hotelSearchConditionText: `📍{{destination}}
🗓{{checkinDate}}-{{checkoutDate}}（{{stayNum}} nights）
{{guestNum}}`,
};
