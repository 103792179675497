export const reputation: any = {
  title: "たびチャットでヨーロッパ旅行に行かれた方の口コミ",
  led: "旅行後のアンケートでは\n満足度94%のご評価をいただきました。",
  reference: "引用元",
  voice1: `パリ、ローマ3泊ずつで、移動を含めて9日間の旅行をしました。
  最初はパリ、ローマ、スペインの3ヶ国を検討していましたが、3ヶ国のパターンと2ヶ国のパターンを迅速にご提案いただきました。
  都市だけでなく、予算と日程に合わせて航空会社やホテルなども何パターンもご提案いただいたので、納得できるまで比較検討した上でスムーズに決めることができました。
  （他の大手旅行会社では1つの質問をしたら次の日に回答が来るスピード感でしたが、たびチャットは1〜3時間で回答が来るので検討をスピーディー進められて本当にストレスフリーでした）
  確定後も、予算やシチュエーションに合わせた現地でのレストランやWi-Fiなどを案内していただきました。
  旅行中も予想より高い宿泊税に驚き、ぼったくられているのかもと不安に思ってLINEで確認した時もすぐに詳細を調べて伝えていただいたので安心できました。
  ツアーではなくある程度個人でヨーロッパ旅行を検討している方には、是非おすすめしたいです。大満足の旅行でした！`,
  person1: "Kyoko Ichimura",
  voice2: `11月にポートランドに行って来ました。初めてのアメリカでたくさん不安もあったのですが、
  ツアーやフライト時間ホテル等、提案や相談をとても丁寧に対応してくれました！
  当日、飛行機の遅延でホテルの到着時間が遅れた事やホテルでの困りごとにも迅速に対応して頂いて本当に嬉しかったです！
  ネットでのやりとりでしたが
  不安になる事なく旅の計画できました！
  
  また海外に行く時はたびチャットさんにお願いします！
  スタッフのみなさん本当にありがとうございました！
  おかけで思い出に残る旅行ができました！`,
  person2: "Ayako Ichiyama",
  voice3: `初めての海外旅行、一人旅で憧れのイギリスに行くためにたびチャットさんを利用させていただきました🇬🇧LINEでのやり取りだけというのに不安はありましたが毎回返信は早く、そして快く対応していただき旅行前から旅行中まで心強かったです。
  海外旅行をツアーなどではなく個人で行きたい方には本当にぜひおすすめしたいです😊 `,
  person3: "Saki",
  voice4: `今回、台湾旅行で初めて利用させて頂きました。
  墾丁は情報が少なく、調べる時間もなかったのでとても助かりました。
  条件をいくつだし、候補を上げていただきその中で選んだだけでしたが、一緒にいった友人には大好評。
  LINEでやり取りが出来たので、ちょこちょこと変更したことも直ぐに対応していただきました。
  『いつでも、相談できる』『丁寧』が最大の魅力ですね。`,
  person4: "IZUMI",
  voice5: `ミッフィーの故郷オランダとチョコレート王国ベルギーに行きました🐇🍫

  大手旅行代理店のツアーは高額且つ日程も限られていたので、他社を探していたところたびチャットさんを見つけました🙇
  希望日時でご案内いただけた上に、大手よりもホテルのグレードもよく、そしてとても安くて最初はビックリして不安になりました🥺💦
  ですが、ラインのやり取りも丁寧でどんな質問も迅速にお答えいただけたので、大丈夫と思いこちらで予約させていただきました😆
  
  結果、とても快適な旅行ができ大変満足いくものになりました！！
  また是非利用させていただきたいと思います！ありがとうございました😊`,
  person5: "YURI N",
  voice6: `３０年来の夢だったギリシア旅行を提案してもらいました。対応、提案ともに満足です。

  希望内容の変更もあって何度も提案してもらいました。面倒だったでしょうが、助かりました。
  
  また別の機会があったら、利用させていただきたいと思う代理店さんでした。`,
  person6: "小林博",
  voice7: `定年退職したら以前行ってたニューヨークに1ヶ月程行く！と決めてたら、コロナで2年行けず。やっと今回行くことができました。
  お金も底を付いてきた為、無理を言って予算少しオーバーくらいで行くことが出来ました。
  自分でホテル探した料金と提案して頂いた料金が違う等、ワガママな質問にも丁寧に答えて頂き、小さな質問やESTAで困っていても、必ず親切に対応して下さいました。
  質問してから回答していただく時間も早くて、準備がスムーズににできました。
  予定をアナハイムで、大谷選手見る、ロスでディズニーとユニバーサルに行く、ニューヨークはブラブラと計画を話すと、日程を考えてくださって、アナハイムはホテルもディズニーには徒歩で行ける所でした。
  ケガで、出場しない大谷選手もファン感謝デーの表彰式には来たので、見れたし、ロスのホテルはダウンタウンから少し離れてましたが、同じホテルに泊まってた素敵な日本人にお会いできて、観光地一緒に車で回って頂けたり、ニューヨークでは、マンハッタンから地下鉄で一本で行ける所だったり、本当にラッキーな事ばかりでした。
  ただニューヨークは、マンハッタンから1時間かかる為、夜のマンハッタンを見るには地下鉄が怖いので、今回は見ることができなかったのが心残りです。
  格安で、アパートメントタイプのホテルをお願いしたので、もう少しお金に余裕を持って行けたら良かったと、自分に反省です。
  たびチャットさんのお陰で、思い出いっぱいの一人旅ができました。又海外に行けるようになったら、ぜひたびチャットさんを利用したい！他の人にも勧めたい！と思います。
  担当の方ありがとうございました。`,
  person7: "MAYUMI GOKOH",
};
