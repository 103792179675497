export const payment = {
  status: {
    created: "作成済み",
    authorized: "仮売上",
    confirmed: "確定",
    refunded: "返金済",
    failed: "失敗",
    cancelled: "キャンセル済み",
  },
  paymentType: {
    on_site: "現地払い",
    credit_card: "クレジット払い",
    bank_transfer: "銀行振込",
    later_payment: "あと払い",
    advance_payment: "先払い",
    deposit_payment: "デポジットからの引き落とし",
    government_benefit: "政府給付金",
  },
  billerType: {
    unknown: "不明",
    fness: "エフネス",
    oversease_travel: "OTA",
    travelfusion: "travelfusion",
    vueling: "Vueling",
    expedia: "expedia",
    his: "his",
    marinabay: "marina bay",
    veltra: "veltra",
    omio: "omio",
    tpi: "TPI",
    other: "その他",
  },
};
