import { common } from "./common/en";
import { flightReservationNew } from "./flightReservationNew/en";
import { flightReservationShow } from "./flightReservationShow/en";
import { flightIndex } from "./flightIndex/en";
import { hotelReservationShow } from "./hotelReservationShow/en";
import { hotelIndex } from "./hotelIndex/en";
import { hotelShow } from "./hotelShow/en";
import { search } from "./search/en";
import { hotelReservationNew } from "./hotelReservationNew/en";
import { form } from "./form/en";
import { modal } from "./modal/en";
import { consultationNew } from "./consultationNew/en";
import { dynamicPackage } from "./dynamicPackage/en";
import { dynamicPackageReservationNew } from "./dynamicPackageReservationNew/en";
import { consultationConditionEdit } from "./consultationConditionEdit/en";
import { consultationConditionShow } from "./consultationConditionShow/en";
import { carouselFlight } from "./carouselFlight/en";
import { error } from "./error/en";
import { lp } from "./lp/en";
import { cms } from "./cms/en";
import { reservationShow } from "./reservationShow/en";
import { reservation } from "./reservation/ja";
import { flightRegion } from "./flightRegion/en";
import { hotelRegion } from "./hotelRegion/en";
import { costItem } from "./costItem/en";
import { post } from "./post/en";
import { reputation } from "./reputation/en";
import { en as emailUser } from "./emailUser/en.yml";
import { en as line } from "./line/en.yml";
import { en as consultation } from "./consultation/en.yml";

export const componentEn: any = {
  common,
  flightReservationNew,
  flightReservationShow,
  flightIndex,
  hotelReservationShow,
  hotelReservationNew,
  hotelIndex,
  hotelShow,
  search,
  form,
  modal,
  consultationNew,
  dynamicPackage,
  dynamicPackageReservationNew,
  consultationConditionEdit,
  consultationConditionShow,
  carouselFlight,
  error,
  lp,
  cms,
  reservationShow,
  reservation,
  flightRegion,
  hotelRegion,
  costItem,
  post,
  reputation,
  emailUser,
  line,
  consultation,
};
