export const lp: any = {
  searchFlightByDestinationTitle: "{{destination}}への航空券を検索する",
  flightReferencePriceListTitle: "{{destination}}行きの人気の航空券",
  destinationAbout: "{{destination}}について",
  destinationBestSeason: "{{destination}}のベストシーズン",
  conciergeComment: "コンシェルジュコメント",
  destinationMainAirlines: "{{destination}}行きの主な航空会社",
  accessToCity: "都市部へのアクセス方法",
  searchHotelByDestinationTitle: "{{destination}}のホテルを検索する",
  hotelRecommendedListTitle: "{{destination}}でオススメのホテル",
  surroundingRegions: "近くの地域",
  orderExamples: "オーダー例",
  referencePrice: "参考価格",
};
