export const cruiseArrangement = {
  status: {
    created: "created",
    arranged: "arranged",
    cancelled: "cancelled",
  },
  arrangementType: {
    tumlare: "tumlare",
  },
};
