export const rentalCarArrangement = {
  status: {
    created: "作成済",
    arranged: "手配済",
    cancelled: "キャンセル済",
  },
  arrangementType: {
    expedia: "expedia",
    agent_car: "agent_car",
  },
};
