import { common } from "./common/en";
import { input } from "./input/en";
import { reservation } from "./reservation/en";
import { masterMessage } from "./masterMessage/en";
import { hotel } from "./hotel/en";
import { flight } from "./flight/en";
import { optionalTour } from "./optionalTour/en";
import { scenario } from "./scenario/en";
import { chatNotification } from "./chatNotification/en";
import { crmMessageSchedule } from "./crmMessageSchedule/en";
import { masterMessageGroup } from "./masterMessageGroup/ja";
import { en as form } from "./form/en.yml";
import { en as arrangementPayment } from "./arrangementPayment/en.yml";
import { en as arrangementRefund } from "./arrangementRefund/en.yml";
import { en as chat } from "./chat/en.yml";
import { en as user } from "./user/en.yml";
import { en as question } from "./question/en.yml";
import { en as inHouseNotice } from "./inHouseNotice/en.yml";
import { en as lp } from "./lp/en.yml";
import { en as emailChatUnit } from "./emailChatUnit/en.yml";
import { en as cms } from "./cms/ja.yml";

export const labelEn = {
  common,
  input,
  reservation,
  masterMessage,
  hotel,
  flight,
  optionalTour,
  scenario,
  chatNotification,
  crmMessageSchedule,
  masterMessageGroup,
  cms,
  form,
  arrangementPayment,
  arrangementRefund,
  chat,
  user,
  question,
  inHouseNotice,
  lp,
  emailChatUnit,
};
