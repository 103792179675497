export const insuranceArrangement = {
  status: {
    created: "created",
    arranged: "arranged",
    cancelled: "cancelled",
  },
  arrangementType: {
    tabiho: "tabiho",
  },
};
