export const masterMessage = {
  platformType: {
    all_platform: "全プラットフォーム",
    line: "LINE",
    facebook: "Facebook",
  },
  groupType: {
    greeting: "挨拶",
    hearing: "ヒアリング",
    proposal_starting: "提案開始",
    closing: "予約",
    mine: "自作のみ",
    bot_initial_greeting: "最初のあいさつ",
    bot_remind_hearing: "ヒアリングのリマインド",
    bot_remind_initial_action: "初回アクションのリマインド",
  },
};
