export const reservation = {
  firstName: "First Name",
  lastName: "Last Name",
  numberOfRooms: "{{number}} Room",
  roomIndex: "Room {{index}}",
  totalPrice: "Trip Total",
  otherTaxesIncl: "other taxes incl",
  hotelFee: "Fee At The Property",
  priceBreakdown: "a breakdown of your price",
  email: "Email",
  creditCardNo: "Credit Card No",
  creditCardCvc: "Security Code",
  creditCardExpireMonth: "Expire Month",
  creditCardExpireYear: "Expire Year",
  phoneNumber: "Phone Number",
  address: "Address",
  travelerIndex: "Traveler {{index}}",
  primaryTraveler: "primary person",
  namePassportNotice: "Enter the names exactly as they appear on the passport.",
  passportCountry: "Passport issuing country",
  dateOfBirth: "Date of birth",
  gender: "Gender",
  male: "Male",
  female: "Female",
  breakdown: "Breakdown",
  book: "Book",
  additionalInformation: "Additional information",
  otherCharges: "Other charges",
  passportExpirationDate: "Passport date of expiry",
  passportNumber: "Passport number",
  nationalityCountryCode: "nationalityCountryCode",
  smokingRequest: "Smoking request",
  beddingRequest: "Bedding request",
  specialRequest: "Special request",
  cancellationPolicy: "Cancellation Policy",
  confirmationDeadLine: "Confirmation dead line",
  reservationDate: "Reservation date",
  travelStartDate: "Travel start date",
  travelEndDate: "Travel end date",
  flightDepartureDate: "Flight departure date",
  flightArrivalDate: "Flight arrival date",
  flightExternalServiceReservationId: "GDS reservation number",
  flightAirlineReservationId: "Airline reservation number",
  hotelCheckInDate: "Hotel check-in date",
  hotelCheckOutDate: "Hotel check-out date",
  hotelExternalServiceReservationId: "Hotel reservation ID (external)",
  hotelArrangementId: "Hotel arrangement ID (internal)",
  chatUser: "Chat user",
  chatUserId: "Chat user ID",
  chatUserName: "Chat user name",
  minRangeDate: "Min date",
  maxRangeDate: "Max date",
  reservationSearch: "Reservation search",
  paymentRelated: "Payment related",
  hasPayment: "Payment",
  hasAuthorizedPayment: "Authorized Payment",
  hasCharge: "Charge",
};
