export const masterMessageGroup = {
  masterMessageGroupList: "メッセージグループ一覧",
  masterMessageGroupName: "メッセージグループ名",
  messageGroup: "メッセージグループ",
  groupType: "グループタイプ",
  addMessages: "メッセージを追加する",
  operation: "操作",
  action: "アクション",
  carousel: "カルーセル",
  actionData: "アクションデータ",
  messagePreview: "メッセージプレビュー",
};
