export const flight: any = {
  operatingAirline: "Operating Airline: {{name}}",
  toAirport: "To {{name}} ",
  departingFlight: "Depart",
  returningFlight: "Return",
  roundTripFlight: "Round trip",
  useLcc: "Use LCC",
  notUseLcc: "Do not use LCC",
  codeshare: "Codeshare",
  excludeCodeshare: "Exclude codeshare",
  reservedSeat: "Reserved seat",
  firstDepartureTime: "Departure time",
  lastArrivalTime: "Return time",
  baggageDisplay: "{{bags}} bag(s): {{weight}} ({{price}})",
  additionalBaggage: "Additional baggage",
  addBaggage: "Add baggage",
  quantityFreeBaggagePerPerson:
    "Free baggage allowance: up to {{quantity}} piece(s)/person",
  weightFreeBaggagePerPerson:
    "Free baggage allowance: up to {{weight}}kg(s)/person",
  quantityWeightFreeBaggagePerPerson:
    "Free baggage allowance: up to {{quantity}} ({{weight}}{{weightUnit}}(s)) piece(s)/person",
  noFreeBaggage: "No free baggage included",
  noFreeBaggageInformation: "Please Ask Because No information",
  itineraryNumber: "Itinerary {{index}}",
  baggageWithItinerary: "Itinerary{{index}}: {{baggageText}}",
  baggageAllowance: "【Free】Baggage Allowance",
  additionalBaggageAllowance: "【Paid】Baggage Allowance",
  bookingClass: "Booking class",
  departFrom: "From {{name}}",
  flightDetails: "Flight details",
  checkpoint: "Checkpoints",
  departureAirport: "Departure airport",
  arrivalAirport: "Arrival airport",
  transitAirport: "Transit airport",
  airline: "Airline",
  layover: "Stop hour",
  maxLayover: "Max stop hour",
  departureHour1: "Departure hour 1",
  departureHour2: "Departure hour 2",
  departureHour3: "Departure hour 3",
  departureHour4: "Departure hour 4",
  departureHour5: "Departure hour 5",
  lastDepartureHour: "Last departure hour",
  lastArrivalHour: "Last arrival hour",
  freeBaggageType: "Free baggage",
  agreement: "I agree to the above",
  outbound: "outbound",
  inbound: "inbound",
  selectedAdditionalBaggage: "【Added】Baggage Allowance",
  destination: "Going to",
  origin: "Leaving from",
  departureDate: "Departing",
  returnDate: "Returning",
  age: "Age",
  stopCount: "Stop count",
  sortBy: "Sort by",
};
